import { createSelector } from 'reselect';
import { KoddiUser } from 'api/Admin/User';
import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { initialState } from './reducer';
import { AuthState } from './types';

const selectAuth = (state: ApplicationRootState): AuthState =>
    state.auth || initialState;

export const selectAuthUser: ApplicationOutputSelector<
    KoddiUser | null,
    (res: AuthState) => KoddiUser | null
> = createSelector(selectAuth, (authState) => authState.context.user);
