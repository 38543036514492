export enum ChakraSizes {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
    full = 'full',
}

export enum IconSizes {
    xs = '8px',
    sm = '16px',
    md = '24px',
    lg = '32px',
    xl = '48px',
}
