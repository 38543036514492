/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { KoddiUser } from 'api/Admin/User';
import { AuthAction, UpdateUserAction } from './types';

export function updateUser(user: KoddiUser): UpdateUserAction {
    return {
        type: AuthAction.UPDATE_USER,
        payload: { user },
    };
}
