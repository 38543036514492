import { Border, BorderRadius } from './Borders.config';
import { Colors } from './Colors.config';
import { ChakraSizes } from './Sizes.config';
import { Spacing } from './Spacing.config';
import { FontWeights } from './Typography.config';

export const buttonConfig = (
    hasEmbeddedStyles = false
): Record<string, unknown> => ({
    defaultProps: {
        variant: 'primary',
        cursor: 'pointer',
    },
    baseStyle: {
        border: `${Border.Width.Thin} solid transparent`,
        cursor: 'pointer',
        padding: Spacing.XS,
        fontWeight: hasEmbeddedStyles
            ? FontWeights.regular
            : FontWeights.semibold,
        borderRadius: BorderRadius.SM,
        lineHeight: 1,
    },
    size: {
        sm: {
            fontSize: ChakraSizes.sm,
        },
    },
    variants: {
        wrapper: {
            border: `none`,
            padding: 0,
            px: 0,
            mx: 0,
            borderRadius: 'none',
            height: 'unset',
            width: 'unset',
            minWidth: 'unset',
            display: 'inline-block',
        },
        primary: {
            bg: 'btnPrimary.500',
            color: Colors.white,
            _disabled: {
                bg: Colors.disabled,
            },
            _hover: {
                bg: 'btnPrimary.700',
                _disabled: {
                    bg: Colors.disabled,
                },
            },
        },
        secondary: {
            bg: 'none',
            color: Colors.primaryText,
            _hover: {
                color: Colors.primaryText,
                bg: 'gray.300',
            },
        },
        border: {
            bg: Colors.white,
            color: 'btnPrimary.500',
            fill: 'btnPrimary.500',
            border: `${Border.Width.Thin} solid`,
            borderColor: 'btnPrimary.500',
            _disabled: {
                color: Colors.disabled,
                borderColor: Colors.disabled,
                fill: Colors.disabled,
            },
            _hover: {
                borderColor: 'btnPrimary.700',
                color: 'btnPrimary.700',
                fill: 'btnPrimary.700',
                bg: 'btnPrimary.100',
                _disabled: {
                    bg: 'transparent',
                    color: Colors.disabled,
                    fill: Colors.disabled,
                    borderColor: Colors.disabled,
                },
            },
        },
        // CTextButton has inherited the link variant. only use this variant is specifically instructed
        text: {
            h: 'none',
            p: 0,
            margin: 0,
            fontWeight: 'inherit',
            fontSize: 'inherit',
            minWidth: 0,
            whiteSpace: 'normal',
            textAlign: 'left',
            padding: 0,
            paddingInlineStart: 0,
            paddingInlineEnd: 0,
            border: 'none',
            display: 'inline',
            textDecoration: 'underline',
            verticalAlign: 'baseline',
            _hover: {
                textDecoration: 'none',
                _disabled: {
                    textDecoration: 'underline',
                },
            },
        },
        link: {
            h: 'none',
            border: 'none',
            margin: 0,
            minWidth: 0,
            whiteSpace: 'normal',
            textAlign: 'left',
            padding: 0,
            paddingInlineStart: 0,
            paddingInlineEnd: 0,
            color: Colors.primary,
            fontWeight: 'inherit',
            fontSize: 'inherit',
            textDecoration: 'underline',
            _hover: {
                textDecoration: 'None',
            },
        },
        subtle: {
            padding: `${Spacing.XXS}`,
            margin: 0,
            bg: 'gray.400',
            _hover: {
                bg: 'gray.500',
            },
            h: 'none',
        },
        icon: {
            bg: 'none',
            color: 'gray.600',
            _hover: {
                color: 'gray.800',
                bg: 'gray.400',
            },
            borderRadius: BorderRadius.XL,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            minWidth: 0,
            paddingInlineStart: 0,
            paddingInlineEnd: 0,
            height: '24px',
            width: '24px',
        },
        stacked: {
            display: 'block',
            textWrap: 'wrap',
            height: '100%',
            padding: Spacing.XS,
            margin: 0,
            bg: 'gray.400',
            _hover: {
                bg: 'gray.600',
            },
        },
    },
});
