import { ReportContext } from 'api/Reports';
import { KoddiBudgetType } from 'types';
import { ReportFilter } from '../../../types';
import { BudgetType } from '../KoddiRecommendations/KoddiRecommendations.types';

export type AdGroupBidType = 'CPC' | 'CPM';

export enum BID_TYPE {
    'CPC' = 1,
    'CPM' = 2,
}

export type AdGroupBaseBid = {
    type: AdGroupBidType;
    amount: number | null;
};

export type AdGroupBudgetType = Extract<
    'Daily' | 'Weekly' | 'Monthly' | 'Custom',
    KoddiBudgetType
>;

export type AdGroupTargetType = 'Exact' | 'Bid Boost' | 'Bid Multiplier';

export type AdGroupTarget = {
    id: number;
    type: AdGroupTargetType;
    amount: number;
};

export type AdGroupTargetingSetting = {
    targeting_dimension_id: number | null;
    operator: string | null;
    value: any | null;
    or_group: string | null;
    options: number[] | null;
};

export type AdGroupTargeting = {
    type: string;
    value: number | null;
    settings: AdGroupTargetingSetting[];
};

export type AdGroupRotation = 'Even' | string;

export type AdGroupAd = {
    id: number;
    /** Can be `"Even"` or a custom percentage value. */
    rotation: AdGroupRotation;
};

export type AdGroupBidder = {
    id: string;
    amount: number | null;
    name: string;
    use_base_bid: boolean;
    deleted?: boolean;
    bid_strength?: number;
};

export type AdGroupBudget = {
    type: AdGroupBudgetType;
    amount: number | null;
};

export type AdGroupStatus =
    | 'active'
    | 'pending'
    | 'inactive'
    | 'ended'
    | 'paused'
    | 'invalid';

/** A combination of Bids, Bid Adjustments, Targeting, Ads, and Entities */

export type AdGroupBudgetInfo =
    | {
          base_bid: number;
          budget_end_date: string;
          budget_key: string;
          budget_start_date: string;
          budget_type: string;
          campaign_end_date: string;
          campaign_start_date: string;
          current_budget: number;
          current_pacing_budget: number;
          current_spend: number;
          initial_budget: number;
          is_active: boolean;
          is_paused: boolean;
          member_group_id: number;
          pacing_type: string;
          timezone: string;
      }
    | Record<string, unknown>;

export type AdGroup = {
    id: number;
    ad_group_id: number;
    campaign_id: number;
    campaign_template_id: number | null;
    entity_id: number;
    advertiser_id: number;
    name: string;
    ad_group_name: string;
    start_date: string;
    end_date?: string | null;
    effective_status_id: number | null;
    always_on: boolean;
    status: AdGroupStatus;
    base_bid: AdGroupBaseBid;
    budget: AdGroupBudget;
    targeting: AdGroupTargeting[] | null;
    ads: AdGroupAd[];
    bidders: AdGroupBidder[] | null;
    total_included?: number;
    attribute_key_values?: string[] | null;
    targeting_automation_enabled: boolean;
    asset_associations?: AdGroupAsset[];
};

export type AdGroupAsset = {
    asset_id: number;
    content?: string | null;
    project_id?: string | null;
};

export type AdGroupDraftValues = {
    campaign_id: number;
    name: string;
    start_date: string;
    end_date?: string | null;
    always_on: boolean;
    base_bid: AdGroupBaseBid;
    budget: AdGroupBudget;
    targeting: AdGroupTargeting[];
    asset_associations?: AdGroupAsset[];
    attribute_key_values?: string[] | null;
    targeting_automation_enabled: boolean;
    uuid: string;
};

export type EditedAdGroupDraftValues = AdGroupDraftValues & {
    status: AdGroupStatus;
};

export type AdGroupDraftDetails = {
    ad_group_id: number;
    ad_group_uuid: string;
};

export type AdGroupHistory = {
    timestamp: string;
    ad_group_id: number;
    name: string;
    email: string;
    start_date: string;
    end_date: string | null;
    always_on: boolean;
    base_bid_amount: number;
    is_auto_bid: boolean;
    budget: number;
    budget_type: BudgetType;
    status_id: string;
    targeting_count: number;
};

export type AdGroupPayload = AdGroup & {
    filters?: any[];
};

export type AdGroupEntity = AdGroup & {
    deleted: boolean;
    entity_id?: string;
};

export type BulkEditAdGroupStatus = 'active' | 'pending' | 'paused' | 'ended';

export type EntitiesPayload = {
    ad_group_ids: number[];
    entity_id: number;
}[];

export type AllEntitiesPayload = {
    ad_group_ids: number[];
    selectedAdGroupIds: Record<string, any>;
    context: ReportContext;
    dimensions: string[];
    endDate: Date;
    filters: Omit<ReportFilter, 'or_group'>[];
    startDate: Date;
    fields: string[];
};

/** The input data for creating an advertiser ad group input. */
export type CreateAdvertiserAdGroupInput = Required<
    Omit<
        AdGroup,
        | 'id'
        | 'end_date'
        | 'entity_count'
        | 'total_entity_count'
        | 'total_included'
        | 'attribute_key_values'
        | 'asset_associations'
    >
> &
    Partial<Pick<AdGroup, 'end_date' | 'total_included'>>;

/** The input data for updating an advertiser ad group input. */
export type UpdateAdvertiserAdGroupInput = Partial<
    Omit<AdGroup, 'id' | 'advertiser_id' | 'campaign_id'>
> &
    Partial<Pick<AdGroup, 'end_date'>>;

export type BidStrength = {
    bid_strength: number;
};

export type PCLSum = { [key: string]: number };

export type AdGroupSpecificArgs = {
    memberGroupId: number;
    advertiserId: number;
    adGroupId: number;
};
export type AdvertiserSpecificArgs = {
    memberGroupId: number;
    advertiserId: number;
};
