import {
    Box,
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Colors, FontSizes, Spacing } from '../ChakraTheme';
import { CCheckIcon, CWarningIcon } from '../Icons';
import { CRow } from '../Layouts';
import { ModalFooterButtonGroup } from './atoms/ModalFooterButtonGroup.atom';
import { CModalOverlay } from './atoms/ModalOverlay.atom';
import {
    ActionableModalComponentProps,
    FormModalComponentProps,
    ModalComponentProps,
    ModalProps,
    ModalSizes,
    ModalSubvariantTypes,
    ModalTypes,
    SpecificModalComponentProps,
    UseModal,
} from './Modal.types';

const CModalBase = ({
    content,
    title,
    id,
    footer,
    openModalId,
    onClose,
    onCancel,
    onSubmit,
    type,
    scrollBehavior,
    submitButtonText,
    closeOnOverlayClick = true,
    closeOnEsc = true,
    icon,
    size = ModalSizes.Medium,
    onOverlayClick,
    onEsc,
    mustTakeAction = false,
    closeOnSubmit = true,
    showFooter = true,
}: ModalProps): JSX.Element => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const showHeader = title || icon;

    const handleClose = useCallback(() => {
        onCancel?.();
        onClose();
    }, [onClose, onCancel]);

    const handleSubmit = useCallback(async () => {
        try {
            setIsSubmitting(true);
            await onSubmit?.();
            if (closeOnSubmit) {
                onClose();
            }
        } finally {
            setIsSubmitting(false);
        }
    }, [onClose, onSubmit, closeOnSubmit]);

    const renderedFooter = useMemo(() => {
        if (type === ModalTypes.Form) return null;
        if (footer) {
            return <ModalFooter>{footer()}</ModalFooter>;
        }
        return (
            <ModalFooter>
                <ModalFooterButtonGroup
                    type={type}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    handleClose={handleClose}
                    id={id}
                    submitButtonText={submitButtonText}
                    isLoading={isSubmitting}
                />
            </ModalFooter>
        );
    }, [
        type,
        footer,
        handleSubmit,
        isSubmitting,
        handleClose,
        id,
        submitButtonText,
    ]);
    return (
        <Modal
            isCentered
            isOpen={openModalId === id}
            onClose={handleClose}
            id={id}
            closeOnOverlayClick={mustTakeAction ? false : closeOnOverlayClick}
            closeOnEsc={mustTakeAction ? false : closeOnEsc}
            size={size}
            onEsc={onEsc}
            onOverlayClick={onOverlayClick}
            scrollBehavior={scrollBehavior}
        >
            <CModalOverlay onClick={handleClose} />
            <ModalContent
                data-test={`${id}--modal`}
                pt={!showHeader ? Spacing.SM : undefined}
                pb={!renderedFooter ? Spacing.SM : undefined}
            >
                {showHeader && (
                    <ModalHeader>
                        <CRow mb={Spacing.SM} alignItems="center">
                            {icon && icon}
                            {title && title}
                        </CRow>
                        <Divider />
                    </ModalHeader>
                )}
                {!mustTakeAction && <ModalCloseButton />}
                <ModalBody>{content}</ModalBody>
                {showFooter && renderedFooter}
            </ModalContent>
        </Modal>
    );
};

export const useCModal = (): UseModal => {
    const [openModalId, setOpenModalId] = useState<string | null>(null);

    const openModal = useCallback((id: string) => setOpenModalId(id), []);
    const closeModal = useCallback(() => setOpenModalId(null), []);

    const getIcon = (subvariant: any) => {
        switch (subvariant) {
            case ModalSubvariantTypes.Warning:
                return (
                    <CWarningIcon
                        fontSize={FontSizes['4xl']}
                        fill={Colors.warning}
                    />
                );
            case ModalSubvariantTypes.Error:
                return (
                    <CWarningIcon
                        fontSize={FontSizes['4xl']}
                        fill={Colors.error}
                    />
                );
            case ModalSubvariantTypes.Success:
                return (
                    <CCheckIcon
                        fontSize={FontSizes['4xl']}
                        fill={Colors.success}
                    />
                );
            default:
                return undefined;
        }
    };

    const ModalComponent = useCallback(
        ({ ...props }: ModalComponentProps) => {
            return CModalBase({
                openModalId,
                onClose: closeModal,
                type: ModalTypes.Generic,
                ...props,
            });
        },
        [closeModal, openModalId]
    );

    const InformationModal = useCallback(
        ({ ...props }: SpecificModalComponentProps) => {
            const icon = getIcon(ModalSubvariantTypes.Information);
            return CModalBase({
                icon,
                openModalId,
                onClose: closeModal,
                type: ModalTypes.Information,
                ...props,
            });
        },
        [closeModal, openModalId]
    );

    const InformationErrorModal = useCallback(
        ({ ...props }: SpecificModalComponentProps) => {
            const icon = getIcon(ModalSubvariantTypes.Error);
            return CModalBase({
                icon,
                openModalId,
                onClose: closeModal,
                type: ModalTypes.Information,
                ...props,
            });
        },
        [closeModal, openModalId]
    );

    const InformationWarningModal = useCallback(
        ({ ...props }: SpecificModalComponentProps) => {
            const icon = getIcon(ModalSubvariantTypes.Warning);
            return CModalBase({
                icon,
                openModalId,
                onClose: closeModal,
                type: ModalTypes.Information,
                ...props,
            });
        },
        [closeModal, openModalId]
    );

    const InformationSuccessModal = useCallback(
        ({ ...props }: SpecificModalComponentProps) => {
            const icon = getIcon(ModalSubvariantTypes.Success);
            return CModalBase({
                icon,
                openModalId,
                onClose: closeModal,
                type: ModalTypes.Information,
                ...props,
            });
        },
        [closeModal, openModalId]
    );

    const ConfirmationModal = useCallback(
        ({ ...props }: ActionableModalComponentProps) => {
            const icon = getIcon(ModalSubvariantTypes.Information);
            return CModalBase({
                icon,
                openModalId,
                onClose: closeModal,
                type: ModalTypes.Confirmation,
                ...props,
            });
        },
        [closeModal, openModalId]
    );

    const ConfirmationWarningModal = useCallback(
        ({ ...props }: ActionableModalComponentProps) => {
            const icon = getIcon(ModalSubvariantTypes.Warning);
            return CModalBase({
                icon,
                openModalId,
                onClose: closeModal,
                type: ModalTypes.Confirmation,
                ...props,
            });
        },
        [closeModal, openModalId]
    );

    // Getting multiple renders here that seem to be related to react-final-form:
    // * FormModal renders the content twice.  The form object (formProps below) is updating at least the dirtyFieldsSinceLastSubmit field, changing from empty object to one filled booleans.  This seems to be causing the form within the modal (useCampaignDuplicationModal.hooks.tsx) to rerender, though not the modal.
    // * When the campaign duplication modal is opened (useCampaignDuplicationModal.hooks.tsx), FormModal fires three times with the following ids:  save-report-modal, duplicate-campaign--modal, handle-budget--modal
    const FormModal = useCallback(
        ({
            content,
            id,
            onFormSubmit,
            initialFormValues,
            validate,
            submitButtonText,
            successAction,
            handleClose,
            errorAction,
            ...props
        }: FormModalComponentProps) => {
            const type = ModalTypes.Form;
            const formModalContent = () => {
                return (
                    <Form
                        onSubmit={onFormSubmit}
                        initialValues={initialFormValues}
                        validate={validate}
                        successAction={successAction}
                        render={(formProps) => {
                            const {
                                handleSubmit,
                                valid,
                                submitting,
                            } = formProps;
                            const handleModalSubmit = async () => {
                                try {
                                    await handleSubmit();
                                    successAction?.();
                                } catch {
                                    errorAction?.();
                                }
                            };
                            return (
                                <form onSubmit={handleModalSubmit}>
                                    {content(formProps)}
                                    <Box
                                        display="flex"
                                        justifyContent="end"
                                        w="100%"
                                    >
                                        <ModalFooterButtonGroup
                                            id={id}
                                            handleSubmit={handleModalSubmit}
                                            isSubmitting={!!submitting}
                                            submitButtonText={submitButtonText}
                                            handleClose={
                                                handleClose ?? closeModal
                                            }
                                            type={type}
                                            disableSubmit={!valid}
                                            isLoading={submitting}
                                        />
                                    </Box>
                                </form>
                            );
                        }}
                    />
                );
            };

            return CModalBase({
                id,
                openModalId,
                scrollBehavior: 'inside',
                onClose: handleClose ?? closeModal,
                closeOnOverlayClick: false,
                closeOnEsc: false,
                content: formModalContent(),
                type,
                ...props,
            });
        },
        [closeModal, openModalId]
    );

    return {
        openModalId,
        closeModal,
        openModal,
        ModalComponent,
        FormModal,
        InformationModal,
        ConfirmationModal,
        InformationErrorModal,
        InformationWarningModal,
        ConfirmationWarningModal,
        InformationSuccessModal,
    };
};
