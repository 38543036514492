import React, { useCallback, useEffect, useState } from 'react';
import {
    CBoldText,
    CColumn,
    CText,
    useCModal,
} from 'app/koddi-components/src/_ChakraComponents';
import { ModalSubmitText } from 'app/koddi-components/src/_ChakraComponents/Modal/Modal.types';

const modalId = 'refresh-token-prompt';
const RefreshTokenPromptContent = ({
    onExpire,
    isVisible,
}: {
    onExpire: () => Promise<void>;
    isVisible: boolean;
}) => {
    const [time, setTime] = useState(60);

    useEffect(() => {
        if (!isVisible) return () => undefined;
        const decreaseTime = () => {
            setTime(time - 1);
        };
        const timer = setTimeout(() => {
            if (time > 0) {
                decreaseTime();
            } else {
                onExpire();
            }
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [isVisible, onExpire, time]);

    return (
        <CColumn>
            <CText>
                Your session will expire in
                <CBoldText as="span" text={` ${time} `} />
                seconds.
            </CText>
            <CText
                text={`Click "${ModalSubmitText.RefreshToken}" before the timer expires, to avoid being logged out.`}
            />
        </CColumn>
    );
};
export const useRefreshTokenPromptModal = (): {
    openRefreshTokenPrompt: () => void;
    RefreshTokenPrompt: (props: {
        onSubmit: () => Promise<void>;
        onExpire: () => Promise<void>;
    }) => JSX.Element;
} => {
    const { openModal, openModalId, InformationWarningModal } = useCModal();

    const openRefreshTokenPrompt = () => {
        openModal(modalId);
    };

    const RefreshTokenPrompt = useCallback(
        ({ onSubmit, onExpire }) =>
            InformationWarningModal({
                id: modalId,
                title: 'Session Expiration',
                content: RefreshTokenPromptContent({
                    onExpire,
                    isVisible: openModalId === modalId,
                }),
                submitButtonText: ModalSubmitText.RefreshToken,
                onSubmit,
                mustTakeAction: true,
                closeOnEsc: false,
                closeOnOverlayClick: false,
            }),
        [InformationWarningModal, openModalId]
    );

    return {
        openRefreshTokenPrompt,
        RefreshTokenPrompt,
    };
};
