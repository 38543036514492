/* eslint-disable import/no-extraneous-dependencies */
import { ReduxActionWithPayload } from 'types';
import { KoddiUser } from 'api/Admin/User';

export enum AuthAction {
    UPDATE_USER = 'auth/UPDATE_USER',
}

export type UpdateUserAction = ReduxActionWithPayload<
    AuthAction.UPDATE_USER,
    { user: KoddiUser }
>;

/** All of the available actions that the auth reducer should respond to. */
export type AuthActions = UpdateUserAction;

export type AuthRedux = {
    user: KoddiUser | null;
    error: Error | null;
    message: string | null;
    loading: boolean;
    email: string | null;
    username: string | null;
    session: string | null;
};
export type AuthState = {
    context: AuthRedux;
};
