export type APIErrorMessage = {
    errorResponse: {
        data: { error: string; formatting: { subject: string; rule: string } };
    };
};

export const fallbackErrorMessage = 'An error has occurred.';

const formatAPIError = (error: APIErrorMessage | Error): string => {
    if (error instanceof Error) return error.message;

    if ('errorResponse' in error) {
        const formatting = error.errorResponse.data?.formatting;

        if (!formatting) {
            return error.errorResponse.data?.error || fallbackErrorMessage;
        }

        if (formatting.subject && formatting.rule) {
            return `${formatting.subject}: ${formatting.rule}`;
        }
    }

    return fallbackErrorMessage;
};

export { formatAPIError };
