import {
    Box,
    Skeleton,
    SkeletonCircle,
    SkeletonText,
    HStack,
    SkeletonProps,
} from '@chakra-ui/react';

import React from 'react';
import { CBorderedCard, CWhiteCard } from '../../Cards';
import { Colors } from '../../ChakraTheme';
import {
    BorderRadius,
    BorderWidth,
} from '../../ChakraTheme/configs/Borders.config';
import { Spacing } from '../../ChakraTheme/configs/Spacing.config';
import { CColumn, CRow } from '../../Layouts';

export const CTextSkeleton = (): JSX.Element => <SkeletonText />;

export const CHeadingSkeleton = ({ ...props }: SkeletonProps): JSX.Element => (
    <Skeleton
        mb={Spacing.XS}
        width="60%"
        {...props}
        height="16px"
        minW="30px"
        maxW="120px"
    />
);

export const CIconSkeleton = ({ ...props }: SkeletonProps): JSX.Element => (
    <Skeleton
        height="24px"
        width="24px"
        {...props}
        borderRadius={BorderRadius.XL}
    />
);

export const CCardSkeleton = (): JSX.Element => (
    <CWhiteCard>
        <CHeadingSkeleton />
        <SkeletonText noOfLines={2} />
    </CWhiteCard>
);

export const CRadioCardSkeleton = (): JSX.Element => (
    <CBorderedCard p={0} maxWidth="530px">
        <HStack p={Spacing.MD} gap={Spacing.SM}>
            <SkeletonCircle size="12px" />
            <Box width="100%">
                <CHeadingSkeleton />
                <SkeletonText noOfLines={1} />
            </Box>
        </HStack>
    </CBorderedCard>
);

export const CInputSkeleton = (): JSX.Element => (
    <CColumn py={Spacing.XXS} width="100%" gap={Spacing.XXS}>
        <Skeleton
            height="14px"
            width="20%"
            minW="80px"
            borderRadius={BorderRadius.SM}
        />
        <Skeleton
            height="28px"
            width="100%"
            minW="120px"
            mb={Spacing.SM}
            borderRadius={BorderRadius.SM}
        />
    </CColumn>
);

export const CSelectSkeleton = (): JSX.Element => (
    <Skeleton
        height="28px"
        width="100%"
        minW="120px"
        mb={Spacing.SM}
        borderRadius={BorderRadius.SM}
    />
);

export const CLongCardWithHeadingSkeleton = (): JSX.Element => (
    <CWhiteCard>
        <CHeadingSkeleton />
        <SkeletonText noOfLines={14} />
    </CWhiteCard>
);

export const CBarChartSkeleton = ({
    numberOfBars,
    chartHeight,
}: {
    numberOfBars: number;
    chartHeight: number;
}): JSX.Element => (
    <CRow
        noBreak
        justifyContent="center"
        alignItems="end"
        borderColor="transparent"
        borderLeft={`${BorderWidth.Thin} solid`}
        borderLeftColor={Colors.skeleton}
        borderBottom={`${BorderWidth.Thin} solid`}
        borderBottomColor={Colors.skeleton}
        height={chartHeight}
    >
        {Array.from(Array(numberOfBars)).map((_, index) => (
            <Skeleton
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}-skeleton`}
                h={`${(index + 1) * (100 / numberOfBars)}%`}
                w={`${90 / numberOfBars}%`}
                maxW="75px"
            />
        ))}
    </CRow>
);
