/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import createSimpleSaga, {
    createSimpleSagaWithCache,
} from 'utils/createSimpleSaga';
import { takeEvery, all, takeLeading, call } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { RoleList } from 'api/Admin/Role';
import {
    getRoleListError,
    getRoleListSuccess,
    editRoleSubmitSuccess,
    editRoleSubmitError,
} from './actions';
import { RoleAction, EditRoleSubmitAction } from './types';
import { selectRoleList } from './selectors';
import { createRolePermissionEdits } from './utils';
import {
    editorSubmitSuccess,
    closeEditor,
    editorSubmitError,
} from '../editor/actions';
import { showAdminAlertSuccess } from '../alert/actions';

// Role List Sagas

export const getRoleListApi = (): Promise<RoleList> =>
    KoddiAPI.Admin.Role.getList();

export const getRoleListSaga = createSimpleSagaWithCache(
    getRoleListApi,
    getRoleListSuccess,
    getRoleListError,
    selectRoleList
);

export function* watchGetRoleList() {
    yield takeEvery(RoleAction.GET_ROLE_LIST, getRoleListSaga);
}

// Edit Role Sagas

export function* editRoleApi({ payload: { data } }: EditRoleSubmitAction) {
    const { id, name: title, activities } = data;

    const rolePermissionEdits = createRolePermissionEdits(
        id,
        activities
    ).map(([func, ...args]) => call(func, ...args));

    yield all(rolePermissionEdits);

    yield call(KoddiAPI.Admin.Role.update, { role_title: title, role_id: id });
}

export const editRoleSubmitSaga = createSimpleSaga(
    editRoleApi,
    [
        editRoleSubmitSuccess,
        editorSubmitSuccess,
        closeEditor,
        () => showAdminAlertSuccess(),
    ],
    [editRoleSubmitError, editorSubmitError]
);

export function* watchEditRoleSubmit() {
    yield takeLeading(RoleAction.EDIT_ROLE_SUBMIT, editRoleSubmitSaga);
}

function* roleSagas() {
    yield all([watchGetRoleList(), watchEditRoleSubmit()]);
}

SagaRegistry.registerSaga(roleSagas);
