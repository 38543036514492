import React from 'react';
import { SingleSelectFieldProps } from '../SelectField.types';
import { CSelectFieldBase } from '../SelectField.component';
import { IconSearchInput } from '../atoms/IconSearchInput.atom';
import { CSelectFieldPaginated } from './SelectFieldPaginated';
import { OptionType } from '../../../Types';

export const CSelectField = <T extends string | number = string>({
    ...props
}: SingleSelectFieldProps<T>): JSX.Element => <CSelectFieldBase {...props} />;

export const CVirtualizedSelectField = <T extends string | number = string>({
    ...props
}: SingleSelectFieldProps<T>): JSX.Element => (
    <CSelectFieldBase {...props} isVirtualized />
);

export const CSplitSingleSelectField = <T extends string | number = string>({
    isMulti = false,
    isPaginated = false,
    fetchOptions,
    height = 32,
    ...props
}: SingleSelectFieldProps<T> & {
    isMulti?: boolean;
    isPaginated?: boolean;
    fetchOptions?: (
        searchValue: string | undefined,
        page: number
    ) => Promise<OptionType<string | number>[]>;
    trackedPagination?: {
        page: number;
        count: number;
    };
    defaultValues?: OptionType[];
}): JSX.Element => {
    const components = {
        SingleValue: () => null,
        Input: IconSearchInput,
        LoadingIndicator: () => null, // this will overflow, we are handling loading in IconSearchInput
    };
    if (isPaginated && fetchOptions)
        return (
            <CSelectFieldPaginated<T>
                {...props}
                fetchOptions={fetchOptions}
                isMulti={isMulti}
                shouldWrap={false}
                isClearable={false}
                placeholder=""
                components={components}
                height={height}
            />
        );

    return (
        <CSelectFieldBase<T>
            {...props}
            isMulti={isMulti}
            shouldWrap={false}
            isClearable={false}
            resetSearchOnSelection
            placeholder=""
            components={components}
            height={32}
        />
    );
};
