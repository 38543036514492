/* eslint-disable no-bitwise */
import { KoddiTheme } from 'koddi-components/ThemeProvider';
import { generateTints } from '../../Chakra.utils';

export const colorConfig = (theme: KoddiTheme): Record<string, any> => {
    const primary = generateTints(theme.primary);
    const secondary = generateTints(theme.secondary);
    const gray = generateTints(theme.gray);
    const btnPrimary = generateTints(theme.btnPrimary);
    const btnSecondary = generateTints(theme.btnSecondary);
    return {
        primary,
        secondary,
        gray,
        btnPrimary,
        btnSecondary,

        primaryColor: theme.primary,
        secondaryColor: theme.secondary,
        grayColor: theme.gray,

        grayLight: gray[300],
        grayLighter: gray[200],
        grayLightest: gray[100],

        grayDark: gray[600],
        grayDarker: gray[800],
        grayDarkest: gray[900],

        grayCool: theme.coolGray,
        slate: theme.slate,

        appNavBackground: theme.headerBackground,
        // backwards compatible with old header brand values
        avatarBackground: theme?.avatarColor || theme?.headerTextColor,
        avatarText: theme.headerBackground,
        // extend the above for all colors
    };
};

export enum ColorSchemes {
    primary = 'primary',
    secondary = 'secondary',
    btnPrimary = 'btnPrimary',
    btnSecondary = 'btnSecondary',
    gray = 'gray',
    success = 'green',
    error = 'red',
    warning = 'yellow',
}

export enum Colors {
    // client defined colors
    primary = 'primaryColor',
    secondary = 'secondaryColor',
    gray = 'gray.500',
    disabled = 'gray.700',

    btnPrimary = 'btnPrimary.500',
    btnSecondary = 'btnSecondary.500',

    grayLight = 'grayLight',
    grayLighter = 'grayLighter',
    grayLightest = 'grayLightest',

    grayDark = 'grayDark',
    grayDarker = 'grayDarker',
    grayDarkest = 'grayDarkest',

    grayCool = 'grayCool',
    slate = 'slate',
    cardGray = 'gray.100',

    white = 'white',
    black = 'black',

    appNavBackground = 'appNavBackground',
    avatarBackground = 'avatarBackground',
    avatarText = 'appNavBackground',

    inputIcon = 'gray',

    // system defined colors
    successDark = 'green.800',
    success = 'green.500',
    successLight = 'green.100',

    errorDark = 'red.800',
    error = 'red.500',
    errorLight = 'red.100',
    warningDark = 'orange.700',
    warning = 'orange.400',
    warningLight = 'orange.100',
    warningLighter = 'yellow.500',

    skeleton = 'gray.400',

    // new standards
    pageBackground = '#F7F8FC',
    primaryText = '#131126',
    secondaryText = '#6D82A4',

    muted = '#F9FAFB',
}

export type ColorScale = {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
};

type BaseColors = {
    transparent: string;
    current: string;
    whiteAlpha: ColorScale;
    blackAlpha: ColorScale;
    gray: ColorScale;
    red: ColorScale;
    orange: ColorScale;
    yellow: ColorScale;
    green: ColorScale;
    teal: ColorScale;
    blue: ColorScale;
    cyan: ColorScale;
    purple: ColorScale;
    pink: ColorScale;
    linkedin: ColorScale;
    facebook: ColorScale;
    messenger: ColorScale;
    whatsapp: ColorScale;
    twitter: ColorScale;
    telegram: ColorScale;
    primary: ColorScale;
    secondary: ColorScale;
    pageBackground: '#F7F8FC';
    primaryText: '#131126';
    secondaryText: '#6D82A4';
};

type EnumValues<T> = T[keyof T];

export type ChakraColors = BaseColors &
    Record<EnumValues<typeof Colors>, string> &
    {
        [K in ColorSchemes]: ColorScale;
    };
