import { Box, Collapse } from '@chakra-ui/react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import React, { FunctionComponent, useState } from 'react';
import { CButtonWrapper } from '../Buttons';
import { Colors, IconSizes, Spacing } from '../ChakraTheme';
import { CFAIcon } from '../Icons';
import { CRow } from '../Layouts';
import { CSectionSubHeader } from '../Typography';

export type CTitledCollapseProps = {
    id: string;
    title: string | JSX.Element;
    defaultOpen?: boolean;
    detailSlot?: JSX.Element;
};

export const CTitledCollapse: FunctionComponent<CTitledCollapseProps> = ({
    children,
    id,
    title,
    defaultOpen = false,
    detailSlot,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
    return (
        <Box>
            <CButtonWrapper
                width="100%"
                testId={`${id}-collapse`}
                onClick={() => setIsOpen(!isOpen)}
                py={Spacing.MD}
                px={Spacing.MD}
            >
                <CRow noBreak justifyContent="space-between">
                    <Box width="280px" textAlign="left">
                        {typeof title === 'string' ? (
                            <CSectionSubHeader text={title} />
                        ) : (
                            title
                        )}
                    </Box>

                    {detailSlot}
                    <CFAIcon
                        color={Colors.primaryText}
                        icon={isOpen ? faChevronUp : faChevronDown}
                        size={IconSizes.sm}
                    />
                </CRow>
            </CButtonWrapper>
            <Collapse in={isOpen}>
                <Box pb={Spacing.MD} px={Spacing.MD}>
                    {children}
                </Box>
            </Collapse>
        </Box>
    );
};
