import {
    ButtonGroup as ChakraButtonGroup,
    ButtonGroupProps,
} from '@chakra-ui/react';
import React from 'react';
import { CPrimaryButton } from '../Buttons';
import { BorderWidth, Colors, Spacing } from '../ChakraTheme';

type Props = {
    children: React.ReactNode;
} & ButtonGroupProps;

export const CAttachedButtonGroup = ({
    children,
    size = 'sm',
    ...rest
}: Props): JSX.Element => {
    return (
        <ChakraButtonGroup
            size={size}
            isAttached
            variant="outline"
            bg={Colors.grayCool}
            borderRadius={Spacing.XS}
            w="fit-content"
            borderWidth={BorderWidth.Thinnest}
            borderColor={Colors.gray}
            padding={Spacing.XXXS}
            sx={{
                button: {
                    _hover: { bg: Colors.grayLight },
                    borderRadius: Spacing.XS,
                },
            }}
            {...rest}
        >
            {children}
        </ChakraButtonGroup>
    );
};

const TabButton = ({
    label,
    isActive,
    onClick,
    testId,
}: {
    label: string;
    isActive: boolean;
    onClick: () => void;
    testId: string;
}): JSX.Element => {
    return (
        <CPrimaryButton
            onClick={onClick}
            bg={isActive ? Colors.white : 'transparent'}
            testId={testId}
            color={isActive ? Colors.black : Colors.grayDark}
        >
            {label}
        </CPrimaryButton>
    );
};

export const CStyledButtonGroup = ({
    buttons,
    ...rest
}: {
    buttons: {
        label: string;
        isActive: boolean;
        onClick: () => void;
        testId: string;
    }[];
} & ButtonGroupProps): JSX.Element => {
    return (
        <CAttachedButtonGroup {...rest}>
            {buttons.map((button) => (
                <TabButton
                    key={button.label}
                    label={button.label}
                    isActive={button.isActive}
                    onClick={button.onClick}
                    testId={button.testId}
                />
            ))}
        </CAttachedButtonGroup>
    );
};
