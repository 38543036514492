import { Box, Checkbox, CheckboxProps } from '@chakra-ui/react';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { ColorSchemes } from '../../../ChakraTheme';
import { CFormError } from '../../../Typography';
import { CSingleCheckboxProps } from './Checkbox.types';

export const CCheckbox: FunctionComponent<CheckboxProps> = ({ ...props }) => (
    <Checkbox {...props} colorScheme={ColorSchemes.primary} />
);

export const CSingleCheckbox = ({
    onChange,
    testId,
    label,
    showError = false,
    errorText,
    ...props
}: CSingleCheckboxProps): JSX.Element => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    };
    return (
        <Box height={props?.height} width={props?.width}>
            <CCheckbox
                {...props}
                onChange={handleChange}
                data-test={`${testId}--single`}
            >
                {label}
            </CCheckbox>
            {showError && (
                <CFormError data-test={`${testId}--error`} text={errorText} />
            )}
        </Box>
    );
};
