import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import {
    BorderRadius,
    Colors,
    IconSizes,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import { CFAIcon } from 'app/koddi-components/src/_ChakraComponents/Icons';
import React, { forwardRef, LegacyRef } from 'react';
import { CustomDatePickerInputProps } from '../DatePicker.types';

// eslint-disable-next-line react/display-name
export const CustomDatePickerInput = forwardRef(
    (
        {
            value,
            onClick,
            onFocus,
            onChange,
            name,
            hasError,
            disabled = false,
            showIcon,
        }: CustomDatePickerInputProps,
        ref: LegacyRef<HTMLInputElement>
    ) => {
        const styleProps = {
            borderColor: hasError ? Colors.error : Colors.grayLight,
            borderRadius: BorderRadius.SM,
            backgroundColor: Colors.white,
            _hover: {
                borderColor: Colors.gray,
            },
        };

        return (
            <InputGroup>
                {showIcon && (
                    <InputLeftElement pointerEvents="none">
                        <CFAIcon icon={faCalendar} size={IconSizes.sm} />
                    </InputLeftElement>
                )}
                <Input
                    {...styleProps}
                    data-test={`${name}--date-input`}
                    name={name}
                    value={value}
                    disabled={disabled}
                    onFocus={onFocus}
                    onChange={onChange}
                    onClick={onClick}
                    ref={ref}
                />
            </InputGroup>
        );
    }
);
