import React from 'react';
import { DateRangePickerFooterProps } from './DateRangePickerFooter.types';
import * as Styled from './DateRangePickerFooter.styled';
import { CPrimaryButton, CSecondaryButton } from '../../_ChakraComponents';

const DateRangePickerFooter = ({
    onApply,
    onCancel,
    error,
    inputName,
}: DateRangePickerFooterProps): JSX.Element => {
    return (
        <Styled.DateRangePickerFooter>
            <Styled.DateRangePickerError htmlFor={inputName}>
                {error}
            </Styled.DateRangePickerError>
            <Styled.DateRangePickerButtonGroup>
                {onCancel && (
                    <CSecondaryButton
                        onClick={onCancel}
                        testId="date-range-cancel-button"
                    >
                        Cancel
                    </CSecondaryButton>
                )}
                <CPrimaryButton
                    onClick={onApply}
                    testId="date-range-apply-button"
                >
                    Apply
                </CPrimaryButton>
            </Styled.DateRangePickerButtonGroup>
        </Styled.DateRangePickerFooter>
    );
};

export default DateRangePickerFooter;
