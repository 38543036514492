import {
    Box,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverProps,
    PopoverTrigger,
    Portal,
} from '@chakra-ui/react';
import React from 'react';
import { CElipsisButton } from '../Buttons/Buttons.component';
import { Colors, Spacing } from '../ChakraTheme';
import { CColumn, CRow } from '../Layouts';
import { CBoldText, CText } from '../Typography';

type BasePopoverProps = PopoverProps & {
    triggerButton: JSX.Element;
    body: JSX.Element;
    displayClose?: boolean;
    header?: string | JSX.Element;
};

export const BasePopover = ({
    triggerButton,
    body,
    displayClose = false,
    header,
    ...rest
}: BasePopoverProps): JSX.Element => {
    return (
        <Popover {...rest}>
            <PopoverTrigger>{triggerButton}</PopoverTrigger>
            <PopoverContent
                width="auto"
                whiteSpace="nowrap"
                display="inline-block"
            >
                {displayClose && <PopoverCloseButton />}
                {header && <PopoverHeader>{header}</PopoverHeader>}
                <PopoverBody>{body}</PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

type CIconDropdownProps = {
    options: JSX.Element[];
} & PopoverProps;

export const CIconDropdown = ({
    options,
    ...rest
}: CIconDropdownProps): JSX.Element => {
    return (
        <BasePopover
            {...rest}
            triggerButton={<CElipsisButton testId="dropdown-elipsis" />}
            trigger="click"
            placement="right"
            offset={[0, 10]}
            matchWidth
            body={<CColumn>{options}</CColumn>}
        />
    );
};

export const DarkenedBackgroundPopover = ({
    triggerButtonContent,
    body,
    header,
    footer,
    isOpen,
    handleClose,
    ...rest
}: {
    triggerButtonContent: JSX.Element;
    body: string;
    isOpen: boolean;
    handleClose?: () => void;
    header: string;
    footer?: JSX.Element;
}): JSX.Element => {
    return (
        <Popover
            placement="right"
            closeOnBlur={false}
            isOpen={isOpen}
            onClose={handleClose}
            returnFocusOnClose
            {...rest}
        >
            <PopoverTrigger>{triggerButtonContent}</PopoverTrigger>
            {isOpen && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    width="100vw"
                    height="100vh"
                    bg="rgba(0, 0, 0, 0.6)"
                    zIndex="docked"
                />
            )}
            <PopoverContent
                width="auto"
                whiteSpace="nowrap"
                display="inline-block"
                zIndex="popover"
                position="relative"
            >
                <PopoverCloseButton />
                {header && (
                    <PopoverHeader>
                        <CBoldText text={header} />
                    </PopoverHeader>
                )}
                <PopoverArrow />
                <PopoverBody>
                    <CText
                        whiteSpace="normal"
                        w="400px"
                        mb={footer ? 'none' : Spacing.MD}
                        text={body}
                    />
                </PopoverBody>
                {footer && (
                    <PopoverFooter>
                        <CRow justifyContent="flex-end">{footer}</CRow>
                    </PopoverFooter>
                )}
            </PopoverContent>
        </Popover>
    );
};

export const CTooltipPopover = ({
    trigger = 'hover',
    placement = 'auto',
    triggerButtonContent,
    title,
    body,
    excludeWrapper = false,
}: PopoverProps & {
    triggerButtonContent: JSX.Element;
    title: string;
    body: string;
    excludeWrapper?: boolean;
}): JSX.Element => {
    return (
        <Popover trigger={trigger} placement={placement} isLazy>
            <PopoverTrigger>
                {excludeWrapper ? (
                    triggerButtonContent
                ) : (
                    <Box position="relative" width="fit-content">
                        {triggerButtonContent}
                    </Box>
                )}
            </PopoverTrigger>
            <Portal>
                <PopoverContent zIndex={2}>
                    <PopoverBody>
                        <CColumn>
                            <CText fontWeight="bold">{title}</CText>
                            <CText color={Colors.grayDark}>{body}</CText>
                        </CColumn>
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};
