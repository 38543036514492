/* eslint-disable no-bitwise */
import { useMediaQuery } from '@chakra-ui/react';
import { Breakpoints } from './ChakraTheme/configs/BreakPoints.config';

/**
 * use to generate consistent object of responsive  styles
 * @param smallValue
 * @param regularValue
 * @returns
 */
export const responsiveStyles = (
    smallValue: number | string,
    regularValue: number | string
): any => {
    return {
        base: smallValue,
        md: regularValue,
    };
};

/**
 * Use to set responsive height based on screen height breakpoints.
 */
export const useResponsiveHeight = (
    shortScreenHeight: number,
    mediumScreenHeight: number,
    tallScreenHeight: number
): number => {
    const [isShortScreen] = useMediaQuery(`(max-height: ${Breakpoints.sm})`);
    const [isMediumScreen] = useMediaQuery(`(max-height: ${Breakpoints.md})`);

    if (isShortScreen) return shortScreenHeight;
    if (isMediumScreen) return mediumScreenHeight;
    return tallScreenHeight;
};

export type RGBA = {
    r: number;
    g: number;
    b: number;
    a?: number;
};

export const hexToRgb = (hex: string): RGBA => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
    };
};

// Helper function to convert RGB back to hex
export const rgbToHex = ({ r, g, b }: RGBA): string => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

/** Function by chatGPT
 * Generate an array of 10 tints of a given hex color.
 *
 * @param {string} hexColor - The input hex color code (e.g., "#3498db").
 * @returns {string[]} - An array of 10 lighter tints in hex format.
 */
export const generateTints = (hexColor: string): { [key: number]: string } => {
    // Helper function to convert hex to RGB

    // Blend a color with white by a given factor (0 to 1)
    function blendWithWhite({ r, g, b }: RGBA, factor: number) {
        return {
            r: Math.round(r + (255 - r) * factor),
            g: Math.round(g + (255 - g) * factor),
            b: Math.round(b + (255 - b) * factor),
        };
    }
    function blendWithBlack({ r, g, b }: RGBA, factor: number) {
        return {
            r: Math.round(r * (1.5 - factor)),
            g: Math.round(g * (1.5 - factor)),
            b: Math.round(b * (1.5 - factor)),
        };
    }

    // Parse the input color
    const rgb = hexToRgb(hexColor);

    // Generate tints
    const tints: {
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
    } = {
        50: '',
        100: '',
        200: '',
        300: '',
        400: '',
        500: '',
        600: '',
        700: '',
        800: '',
        900: '',
    };
    type TintKey = keyof typeof tints;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
        switch (true) {
            case i === 0:
                tints[50] = rgbToHex(blendWithWhite(rgb, (10 - 0.5) / 10));
                break;
            case i < 5:
                tints[(`${i}00` as unknown) as TintKey] = rgbToHex(
                    blendWithWhite(rgb, (10 - i) / 10)
                );
                break;
            case i === 5:
                tints[500] = hexColor;
                break;
            default:
                tints[(`${i}00` as unknown) as TintKey] = rgbToHex(
                    blendWithBlack(rgb, (10 - (10 - i)) / 10)
                );
        }
    }
    return tints;
};
