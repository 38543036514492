import { Reducer, AnyAction } from 'redux';
import { AuthActions, AuthAction, AuthState } from './types';

export const initialState: AuthState = {
    context: {
        user: null,
        error: null,
        message: null,
        loading: false,
        email: null,
        username: null,
        session: null,
    },
};

const authReducer = (state = initialState, action: AuthActions): AuthState => {
    switch (action.type) {
        case AuthAction.UPDATE_USER:
            return {
                ...state,
                context: { ...state.context, user: action.payload.user },
            };
        default:
            return state;
    }
};

export default authReducer as Reducer<AuthState, AnyAction>;
