import { extendTheme, Theme, ThemeConfig } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useAppTheme } from 'redux-core/app/theme/hooks';
import defaultTheme from 'themes/default';
import { Border, BorderWidth } from './configs/Borders.config';
import { Breakpoints } from './configs/BreakPoints.config';
import { buttonConfig } from './configs/Button.config';
import { ChakraColors, colorConfig, Colors } from './configs/Colors.config';
import { GlobalStyles, EmbeddedGlobalStyles } from './configs/Globals.configs';
import { Shadows } from './configs/Shadows.config';
import { ChakraSizes as Sizes } from './configs/Sizes.config';
import { Spacing } from './configs/Spacing.config';
import {
    FontSizes,
    FontWeights,
    LineHeight,
    Typography,
} from './configs/Typography.config';

export type ChakraThemeType = Theme & {
    styles: {
        global: {
            body: {
                color: Colors;
                lineHeight: string;
                background: Colors | string;
            };
            p: { margin: string };
            h1: { margin: string };
            h2: { margin: string };
            h3: { margin: string };
            h4: { margin: string };
            h5: { margin: string };
            h6: { margin: string };
            '*::before': { boxSizing: string };
            '*::after': { boxSizing: string };
            '*': { borderStyle: string };
        };
    };
    shadows: {
        outline: string;
        base: typeof Shadows.BASE;
        small: typeof Shadows.SMALL;
        medium: typeof Shadows.MEDIUM;
    };
    breakpoints: typeof Breakpoints;
    colors: ChakraColors;
    borderRadius: {
        radii: typeof Border.Radius;
    };
    fontSizes: typeof Typography.fontSizes;
    lineHeights: typeof Typography.lineHeights;
    fonts: {
        body: string;
        heading: string;
    };
    components: Record<string, ThemeConfig>;
};

export const useChakraTheme = (
    hasEmbeddedStyles?: boolean
): ChakraThemeType => {
    const clientTheme = useAppTheme();
    const theme = clientTheme ?? defaultTheme;
    const defaultFontFamily = `'Inter', Arial, sans-serif`;

    const fontFamily =
        theme.typography.fontFamily === 'sans-serif'
            ? defaultFontFamily
            : theme.typography.fontFamily;

    const chakraCustomTheme = useMemo(
        () =>
            extendTheme({
                styles: {
                    global: hasEmbeddedStyles
                        ? EmbeddedGlobalStyles
                        : GlobalStyles,
                },
                shadows: {
                    outline: 'none',
                    base: Shadows.BASE,
                },
                breakpoints: Breakpoints,
                colors: colorConfig(theme),
                borderRadius: {
                    radii: Border.Radius,
                },
                fontSizes: Typography.fontSizes,
                lineHeights: Typography.lineHeights,
                fonts: {
                    body: fontFamily,
                    heading: fontFamily,
                },
                components: {
                    Input: {
                        defaultProps: {
                            size: Sizes.sm,
                            focusBorderColor: theme.primary,
                            border: `${BorderWidth.Thin} solid ${Colors.gray}`,
                        },
                    },
                    Textarea: {
                        defaultProps: {
                            size: Sizes.sm,
                            focusBorderColor: theme.primary,
                            border: `${BorderWidth.Thin} solid ${Colors.gray}`,
                        },
                    },
                    FormLabel: {
                        defaultProps: {
                            size: 'sm',
                        },
                    },
                    Button: buttonConfig(hasEmbeddedStyles),
                    Heading: {
                        sizes: {
                            lg: {
                                fontSize: FontSizes['2xl'],
                            },
                        },
                        baseStyle: {
                            fontWeight: 500,
                            m: 0,
                        },
                        defaultProps: {
                            size: Sizes.md,
                            m: 0,
                            LineHeight: 'inherit',
                        },
                        variants: {
                            bold: {
                                fontWeight: FontWeights.bold,
                            },
                        },
                    },
                    Text: {
                        defaultProps: {
                            size: Sizes.sm,
                            m: 0,
                        },
                        variants: {
                            disclaimer: {
                                size: Sizes.xs,
                                lineHeight: LineHeight.shorter,
                                color: Colors.secondaryText,
                            },
                            bold: {
                                fontWeight: FontWeights.bold,
                            },
                            label: {
                                fontWeight: FontWeights.semibold,
                                color: Colors.primary,
                            },
                            error: {
                                color: Colors.error,
                            },
                            success: {
                                color: Colors.success,
                            },
                            warning: {
                                color: Colors.warning,
                            },
                        },
                    },
                    Radio: {
                        variants: {
                            atTop: {
                                control: {
                                    alignSelf: 'start',
                                    mt: '1',
                                },
                            },
                        },
                    },
                    HStack: {
                        defaultProps: {
                            gap: Spacing.SM,
                        },
                    },
                    VStack: {
                        defaultProps: {
                            gap: Spacing.SM,
                        },
                    },
                    Stack: {
                        defaultProps: {
                            gap: Spacing.SM,
                        },
                    },
                    ButtonGroup: {
                        defaultProps: {
                            gap: Spacing.SM,
                        },
                    },
                },
            }) as ChakraThemeType,
        [theme, fontFamily, hasEmbeddedStyles]
    );

    return chakraCustomTheme;
};
