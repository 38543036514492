import { Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { DEPLOY_ENV } from 'app/types';

const isAzureEnv = window.DEPLOY_ENV === DEPLOY_ENV.AZURE;

// The back end team requested that this match production
const DEV_CONFIG = {
    audience: 'koddi-one.us.auth0.com',
    domain: 'login.koddi.io',
    clientId: isAzureEnv
        ? 'KpafLHw5VgvzhVqAZeMgeabITXzkSYLQ'
        : 'YycfE8kh0BTp2Qt2u18QV0NOP6NDccGi',
};

const UAT_CONFIG = {
    audience: 'koddi-one-uat.us.auth0.com',
    domain: 'login.uat.koddi.io',
    clientId: isAzureEnv
        ? 'LkBeilXUaDkT0K2qCcBDTOFynwJPVryD'
        : 'jvyoKECHWN9vN2ivuuB7E3qh2hUkKU9Z',
};

const PRODUCTION_CONFIG = {
    audience: 'koddi-one.us.auth0.com',
    domain: 'login.koddi.io',
    clientId: isAzureEnv
        ? 'KpafLHw5VgvzhVqAZeMgeabITXzkSYLQ'
        : 'YycfE8kh0BTp2Qt2u18QV0NOP6NDccGi',
};

const ENV_CONFIG_MAP: { [key: string]: any } = {
    local: DEV_CONFIG,
    development: DEV_CONFIG,
    uat: UAT_CONFIG,
    production: PRODUCTION_CONFIG,
    docker: DEV_CONFIG,
};

const ENV_CONFIG = ENV_CONFIG_MAP[window.TARGET_ENV];

export const AUTH0_CONFIG: Auth0ClientOptions = {
    useRefreshTokens: true,
    cacheLocation: 'localstorage' || 'memory',
    domain: ENV_CONFIG?.domain,
    clientId: ENV_CONFIG?.clientId,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: `https://${ENV_CONFIG?.audience}/api/v2/`,
    },
};

export const RETURN_ROUTE_SESSION_KEY = 'koddi_return_route';
export const RETURN_PARAMS_SESSION_KEY = 'koddi_return_params';

export const USER_ATTEMPTED_LOGIN_KEY = 'user_attempted_login';

export const AUTH_LOGOUT_KEY = 'logged_out_auth0';
