import { Config, FormApi, SubmissionErrors } from 'final-form';
import { FormRenderProps } from 'react-final-form';

export enum ModalTypes {
    Generic = 'generic',
    Confirmation = 'confirmation',
    Information = 'information',
    Form = 'form',
}

export enum ModalSubvariantTypes {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Information = 'information',
}

export enum ModalSubmitText {
    Exit = 'Exit',
    Continue = 'Continue',
    Okay = 'Okay',
    Submit = 'Submit',
    Confirm = 'Confirm',
    Create = 'Create',
    Remove = 'Yes, remove',
    Delete = 'Delete',
    Add = 'Add',
    ActivateCampaign = 'Activate Campaign',
    RefreshToken = 'Keep Me Logged In',
    Update = 'Update',
}

export enum ModalSizes {
    ExtraSmall = 'xs',
    Small = 'sm',
    Medium = 'md',
    Large = 'lg',
    ExtraLarge = 'xl',
    TwoExtraLarge = '2xl',
    FourExtraLarge = '4xl',
    Full = 'full',
    SixExtraLarge = '6xl',
}

export type ModalComponent<T> = ({ ...props }: T) => JSX.Element;

export type ModalComponentProps = Omit<
    ModalProps,
    'onClose' | 'openModalId' | 'type'
>;

export type SpecificModalComponentProps = Omit<
    ModalProps,
    'onClose' | 'openModalId' | 'type'
>;

type WithFooter = SpecificModalComponentProps & {
    footer: () => JSX.Element;
    onSubmit?: never; // `onSubmit` is not needed when `footer` is provided
};

type WithoutFooter = Omit<SpecificModalComponentProps, 'footer'> & {
    footer?: never; // Disallowing `footer` to ensure `onSubmit` is utilized
    onSubmit: (formData?: any) => void; // Making `onSubmit` explicitly required
};

export type ActionableModalComponentProps = WithFooter | WithoutFooter;

export type FinalFormArg = FormRenderProps<
    any,
    Partial<
        (
            values: Record<string, unknown>,
            form: FormApi<
                Record<string, unknown>,
                Partial<Record<string, unknown>>
            >,
            callback?:
                | ((errors?: SubmissionErrors | undefined) => void)
                | undefined
        ) =>
            | void
            | SubmissionErrors
            | Promise<SubmissionErrors | undefined>
            | undefined
    >
>;

export type FormModalComponentProps = Omit<
    SpecificModalComponentProps,
    'subvariant' | 'content' | 'footer' | 'onSubmit'
> & {
    onFormSubmit: (formData: any) => void;
    content: (form: FinalFormArg) => JSX.Element;
    initialFormValues?: Partial<Config<Record<string, unknown>>['onSubmit']>;
    validate?: (values: any) => Record<string, unknown> | undefined;
    successAction?: () => void;
    errorAction?: () => void;
    handleClose?: () => void;
    isLoading?: boolean;
};

export interface FooterButtonGroupProps {
    type: ModalTypes;
    handleClose: () => void;
    handleSubmit?: () => void;
    isSubmitting?: boolean;
    id: string;
    disableSubmit?: boolean;
    submitButtonText: string | undefined;
    isLoading?: boolean;
}
export interface ModalProps {
    content: JSX.Element | string;
    id: string;
    type: ModalTypes;
    openModalId: string | null;
    footer?: () => JSX.Element;
    title?: string;
    onClose: () => void;
    onCancel?: () => void;
    onSubmit?: (formData?: any) => void;
    icon?: JSX.Element | undefined;
    submitButtonText?: ModalSubmitText;
    closeOnOverlayClick?: boolean;
    closeOnEsc?: boolean;
    closeOnSubmit?: boolean;
    size?: ModalSizes;
    onOverlayClick?: () => void;
    onEsc?: () => void;
    mustTakeAction?: boolean;
    scrollBehavior?: 'inside' | 'outside';
    showFooter?: boolean;
}

export interface UseModal {
    openModalId: string | null;
    closeModal: () => void;
    openModal: (id: string) => void;
    ModalComponent: ModalComponent<ModalComponentProps>;
    FormModal: ModalComponent<FormModalComponentProps>;
    InformationModal: ModalComponent<SpecificModalComponentProps>;
    ConfirmationModal: ModalComponent<ActionableModalComponentProps>;
    InformationErrorModal: ModalComponent<SpecificModalComponentProps>;
    InformationWarningModal: ModalComponent<SpecificModalComponentProps>;
    ConfirmationWarningModal: ModalComponent<ActionableModalComponentProps>;
    InformationSuccessModal: ModalComponent<SpecificModalComponentProps>;
}
