import { Center } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import React, { FunctionComponent } from 'react';
import { CButtonWrapper } from '../../Buttons';
import { Colors } from '../../ChakraTheme';
import { CFAIcon } from '../../Icons';
import { CColumn } from '../../Layouts';
import { CText } from '../../Typography';
import { CShadowedCard } from '../Cards.component';

export const AddCard: FunctionComponent<{
    label: string;
    onClick: () => void;
}> = ({ label, onClick }) => {
    return (
        <CButtonWrapper testId={`create-card--${label}`} onClick={onClick}>
            <CShadowedCard minH="240px" height="100%">
                <Center color={Colors.secondaryText} height="100%">
                    <CColumn
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                    >
                        <CFAIcon
                            icon={faPlus}
                            height="32px"
                            width="32px"
                            color="inherit"
                        />
                        <CText text={`New ${label}`} color="inherit" />
                    </CColumn>
                </Center>
            </CShadowedCard>
        </CButtonWrapper>
    );
};
