import {
    RETURN_PARAMS_SESSION_KEY,
    RETURN_ROUTE_SESSION_KEY,
} from 'app/react-ui/modules/Auth0/Auth0.const';
import { LOGIN_ROUTE } from 'app/react-ui/modules/constants/routes';

const publicRouteIdentifiers = ['login', 'register'];

export const handleReturnRouteStorage = ({
    route,
    searchParams = '',
    forceOverwrite = false,
}: {
    route: string;
    searchParams?: string;
    forceOverwrite?: boolean;
}): void => {
    const storedReturnRoute = sessionStorage.getItem(RETURN_ROUTE_SESSION_KEY);
    const storedParamsRoute = sessionStorage.getItem(RETURN_PARAMS_SESSION_KEY);

    if (forceOverwrite) {
        sessionStorage.setItem(RETURN_ROUTE_SESSION_KEY, route);
        sessionStorage.setItem(RETURN_PARAMS_SESSION_KEY, searchParams);
        return;
    }
    if (!storedParamsRoute) {
        sessionStorage.setItem(RETURN_PARAMS_SESSION_KEY, searchParams);
    }
    if (!storedReturnRoute) {
        sessionStorage.setItem(RETURN_ROUTE_SESSION_KEY, route);
    }
};

export function returnToLoginRoute({
    loginKey,
    returnRoute,
}: {
    loginKey?: string;
    returnRoute?: string;
}): string {
    if (returnRoute && loginKey) {
        return `/${loginKey}/login?returnRoute=${returnRoute}`;
    }
    if (!loginKey && returnRoute) {
        return `${LOGIN_ROUTE}?returnRoute=${returnRoute}`;
    }
    if (loginKey && !returnRoute) {
        return `/${loginKey}/login`;
    }
    return LOGIN_ROUTE;
}

export const isPublicRoute = (path: string): boolean => {
    return publicRouteIdentifiers.some((publicRoute) =>
        path.includes(publicRoute)
    );
};

export function getExpirationTime(expiresIn: number): number {
    return +new Date() + expiresIn * 1000;
}

// assumed client name should always be right before the public route identifier
export const getClientNameFromPath = (path: string): string => {
    if (!isPublicRoute(path)) return 'koddi_group';
    const parts = path.split('/');
    let matchedIdentifierIndex: number;
    let i = 0;
    do {
        matchedIdentifierIndex = parts.indexOf(publicRouteIdentifiers[i]);
        i += 1;
    } while (matchedIdentifierIndex < 0);
    return parts[matchedIdentifierIndex - 1] || 'koddi_group';
};

/**
 * returns a default of false if expiration time is not provided
 * @param expirationTime date in milliseconds in which the Auth0 token will expire
 */
export const hasExpirationTimePast = (expirationTime?: number): boolean => {
    if (!expirationTime) return false;
    return expirationTime < new Date().getTime();
};

export const callThenPause = async (
    callback: () => void,
    duration: number
): Promise<true> => {
    callback();
    await new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, duration);
    });
    return true;
};
