import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconSizes } from '../ChakraTheme';

export const CFAIcon = ({
    icon,
    size,
    ...props
}: IconProps & {
    size?: IconSizes;
    icon: IconDefinition;
}): JSX.Element => (
    /* @ts-ignore */
    <Icon w={size} h={size} {...props}>
        <FontAwesomeIcon icon={icon} />
    </Icon>
);
