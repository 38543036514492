import { Box, Flex } from '@chakra-ui/react';
import { faCircle, IconPack } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import React, { FunctionComponent, useState } from 'react';
import { CShadowedCard } from '../../../Cards';
import { BorderRadius, Colors, Spacing } from '../../../ChakraTheme';
import {
    CDV360Icon,
    CFacebookIcon,
    CFAIcon,
    CGoogleAdManagerIcon,
    CGoogleShoppingIcon,
    CMarriottIcon,
    CMetaIcon,
    CPinterestIcon,
    CSA360Icon,
    CTradeDeskIcon,
    CVibenomicsIcon,
    CYIcon,
} from '../../../Icons';
import { CColumn } from '../../../Layouts';
import { CBoldText, CText } from '../../../Typography';
import { ControlProps } from '../../Types';

export interface BaseRadioCardProps<ValueType extends number | string>
    extends ControlProps {
    value: ValueType;
    header: string;
    content: string;
    cardLabel?: string;
    dependantField?: JSX.Element;
    selectedValue?: ValueType | null;
    iconKey?: string;
    isDisabled?: boolean;
    onClick?: () => void;
    iconPackage?: IconPack;
    isStacked?: boolean;
}

export type CustomIconKeys =
    | 'dv360Icon'
    | 'tradeDeskIcon'
    | 'googleShoppingIcon'
    | 'googleAdManagerIcon'
    | 'yIcon'
    | 'sa360Icon'
    | 'vibenomicsIcon'
    | 'pinterestIcon'
    | 'metaIcon'
    | 'facebookIcon'
    | 'marriottIcon';

export type CustomIconMap = Record<CustomIconKeys, JSX.Element>;

const customIcons: CustomIconMap = {
    dv360Icon: <CDV360Icon width="32px" height="32px" mt={Spacing.XXS} />,
    tradeDeskIcon: (
        <CTradeDeskIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
    googleShoppingIcon: (
        <CGoogleShoppingIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
    googleAdManagerIcon: (
        <CGoogleAdManagerIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
    yIcon: <CYIcon width="32px" height="32px" mt={Spacing.XXS} />,
    sa360Icon: <CSA360Icon width="32px" height="32px" mt={Spacing.XXS} />,
    vibenomicsIcon: (
        <CVibenomicsIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
    facebookIcon: <CFacebookIcon width="32px" height="32px" mt={Spacing.XXS} />,
    pinterestIcon: (
        <CPinterestIcon width="32px" height="32px" mt={Spacing.XXS} />
    ),
    metaIcon: <CMetaIcon width="32px" height="32px" mt={Spacing.XXS} />,
    marriottIcon: <CMarriottIcon width="32px" height="32px" mt={Spacing.XXS} />,
};

export const CustomIcon: FunctionComponent<{
    iconPackage?: IconPack;
    iconKey?: CustomIconKeys | keyof IconPack;
    isChecked: boolean;
}> = ({ iconPackage = fas, iconKey = '', isChecked }) => {
    if (iconPackage[iconKey]) {
        return (
            <CFAIcon
                color={Colors.primary}
                icon={iconPackage[iconKey]}
                width="32px"
                height="32px"
                mt={Spacing.XXS}
            />
        );
    }
    if (customIcons[iconKey as CustomIconKeys]) {
        return customIcons[iconKey as CustomIconKeys];
    }
    return (
        <CFAIcon
            icon={isChecked ? iconPackage.faCircleCheck : faCircle}
            mt={Spacing.XXS}
            color={Colors.primary}
        />
    );
};

export const CBaseRadioCard: FunctionComponent<BaseRadioCardProps<
    string | number
>> = ({
    header,
    content,
    dependantField,
    onChange,
    cardLabel,
    isDisabled,
    testId,
    selectedValue,
    iconKey,
    value,
    name,
    onClick,
    iconPackage,
    isStacked = false,
}) => {
    const isChecked = selectedValue === value;
    const showCardLabel = cardLabel && !isDisabled;
    const [isFocused, setIsFocused] = useState(false);
    const handleEnter = () => {
        setIsFocused(true);
    };

    const handleLeave = () => {
        setIsFocused(false);
    };

    const isHighLighted = (isFocused || isChecked) && !isDisabled;
    return (
        <CShadowedCard
            p="0"
            onClick={onClick}
            position="relative"
            maxW="530px"
            data-test={`${testId}--container`}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            borderColor={isHighLighted ? Colors.primary : undefined}
            // color={isHighLighted ? Colors.primary : Colors.grayDark}
            transition="all .25s ease"
        >
            {showCardLabel && (
                <Box
                    bg={Colors.primary}
                    position="absolute"
                    color="white"
                    py={Spacing.XXS}
                    px={Spacing.XS}
                    borderRadius={BorderRadius.XS}
                    borderBottomRightRadius={0}
                    borderTopLeftRadius={0}
                    top="0"
                    right="0"
                >
                    <CBoldText
                        text={cardLabel}
                        fontSize="sm"
                        lineHeight={1}
                        m="0"
                    />
                </Box>
            )}

            <Flex
                gap={Spacing.SM}
                flexDirection={isStacked ? 'column' : 'row'}
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                as="label"
                p={Spacing.LR}
                px={Spacing.MD}
                data-test={`${testId}--radio`}
                height="100%"
            >
                <Box filter={isHighLighted ? 'grayscale(0)' : 'grayscale(1)'}>
                    <input
                        id={`${name}-input`}
                        type="radio"
                        name={name}
                        value={value}
                        hidden
                        checked={isChecked}
                        onChange={(e) => onChange(e.target.value)}
                        disabled={isDisabled}
                    />
                    <CustomIcon
                        iconPackage={iconPackage}
                        iconKey={iconKey}
                        isChecked={isChecked}
                    />
                </Box>

                <CColumn gap={isStacked ? Spacing.SM : Spacing.XXS}>
                    <CBoldText
                        mb="0"
                        text={header}
                        color={Colors.primaryText}
                        data-test={`${testId}--label`}
                    />
                    <CText
                        text={content}
                        mb="0"
                        color={Colors.secondaryText}
                        transition="inherit"
                    />
                    {dependantField && <Box>{dependantField}</Box>}
                </CColumn>
            </Flex>
        </CShadowedCard>
    );
};
