import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuProps,
} from '@chakra-ui/react';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { BorderWidth, Colors, Shadows } from '../ChakraTheme';
import { CFAIcon } from '../Icons';

type CMenuProps = {
    triggerElement: React.ReactNode;
    menuItems: {
        key: string;
        label: string;
        onClick: () => void;
    }[];
} & MenuProps;
export const CMenuBase = ({
    triggerElement,
    menuItems,
    ...menuProps
}: Omit<CMenuProps, 'children'>): JSX.Element => {
    return (
        <Menu {...menuProps}>
            <MenuButton type="button">{triggerElement}</MenuButton>
            <MenuList
                border={`${BorderWidth.Thinnest} solid`}
                borderColor={Colors.grayLight}
                boxShadow={Shadows.BASE}
            >
                {menuItems.map((item) => (
                    <MenuItem key={item.key} onClick={item.onClick}>
                        {item.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export const CIconMenu = ({
    menuItems,
    ...menuProps
}: Omit<CMenuProps, 'triggerElement' | 'children'>): JSX.Element => {
    return (
        <CMenuBase
            triggerElement={<CFAIcon icon={faEllipsisVertical} />}
            menuItems={menuItems}
            {...menuProps}
        />
    );
};
