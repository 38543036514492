import React from 'react';
import { Tag, TagProps } from '@chakra-ui/react';
import { CText } from '../Typography';

export const CTag = ({ children, ...props }: TagProps): JSX.Element => (
    <Tag {...props}>
        <CText noWrap as="span">
            {children}
        </CText>
    </Tag>
);
