import React from 'react';
import { Colors, Typography } from '../ChakraTheme';
import { CColumn } from '../Layouts';
import { CText } from '../Typography';

interface NoDataProps {
    message?: string;
    height?: string | number;
}

export const CNoData = ({
    message,
    height = '400px',
}: NoDataProps): JSX.Element => (
    <CColumn
        height={height ?? '400px'}
        width="100%"
        justifyContent="center"
        alignItems="center"
    >
        <CText
            fontSize={Typography.fontSizes.lg}
            color={Colors.grayDark}
            text={message}
        />
    </CColumn>
);
