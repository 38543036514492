export enum BorderRadius {
    XS = '2px',
    SM = '4px',
    MD = '8px',
    LR = '16px',
    XL = '32px',
}

export enum BorderWidth {
    Thinnest = '1px',
    Thin = '2px',
    Medium = '4px',
    Thick = '6px',
}

export const BorderStyle = {
    Solid: 'solid',
    Dashed: 'dashed',
    Dotted: 'dotted',
};

export const Border = {
    Width: BorderWidth,
    Radius: BorderRadius,
    Style: BorderStyle,
};
