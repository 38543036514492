import { CRow } from 'app/koddi-components/src/_ChakraComponents/Layouts';
import { Spacing } from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import React from 'react';
import { InputProps, components as ReactSelectComponents } from 'react-select';
import { Spinner as CSpinner } from '@chakra-ui/react';
import { CFAIcon } from 'app/koddi-components/src/_ChakraComponents/Icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

export const IconSearchInput = (props: InputProps): JSX.Element => {
    /* @ts-ignore */ // outdated types creating issues... selectProps is in fact part of the props
    const { selectProps } = props;
    const innerProps = {
        ...props,
        placeholder: selectProps?.inputId ?? 'Search',
    };

    return (
        <CRow
            gap={Spacing.XXS}
            noBreak
            alignItems="center"
            width="100%"
            pb="2px"
            position="relative"
        >
            <CFAIcon icon={faSearch} />
            <ReactSelectComponents.Input {...innerProps} />
            {selectProps?.isLoading && (
                <CSpinner
                    size="sm"
                    position="absolute"
                    right={0}
                    borderWidth="1px"
                    pr={Spacing.XXXS}
                />
            )}
        </CRow>
    );
};
