import { KoddiAPIResponse } from 'types';
import CachedKoddiAPI from 'api/api.cachedObject';
import {
    CreateFormUUIDResponse,
    CreateWorkflowUUIDResponse,
    DraftParams,
    DraftRequest,
} from './ConfiguredWorkflowAPI.types';

class KoddiConfiguredWorkflowAPI extends CachedKoddiAPI {
    // drafts
    public createWorkflowDraft = async ({
        memberGroupId,
        advertiserId,
        experienceId,
        workflowConfigId,
        campaignId,
    }: {
        memberGroupId: number;
        advertiserId: number;
        experienceId: number;
        workflowConfigId: number;
        campaignId?: number;
    }): Promise<CreateWorkflowUUIDResponse> => {
        const url = `v1/member_groups/${memberGroupId}/experiences/${experienceId}/workflow_drafts`;
        const response = await this.axios.post<
            KoddiAPIResponse<CreateWorkflowUUIDResponse>
        >(url, {
            workflow_config_id: workflowConfigId,
            advertiser_id: advertiserId,
            campaign_id: campaignId,
        });
        return response.data.result;
    };

    public createWorkflowFormDraft = async ({
        memberGroupId,
        experienceId,
        workflowUUID,
        body,
    }: {
        memberGroupId: number;
        experienceId: number;
        workflowUUID: string;
        body: DraftRequest;
    }): Promise<CreateFormUUIDResponse> => {
        const url = `/v1/member_groups/${memberGroupId}/experiences/${experienceId}/workflow_drafts/${workflowUUID}/page_drafts`;
        const response = await this.axios.post<
            KoddiAPIResponse<CreateFormUUIDResponse>
        >(url, body);
        return response.data.result;
    };

    public updateWorkflowFormDraft = async ({
        memberGroupId,
        experienceId,
        workflowUUID,
        formUUID,
        body,
    }: {
        memberGroupId: number;
        experienceId: number;
        workflowUUID: string;
        formUUID: string;
        body: DraftRequest;
    }): Promise<CreateFormUUIDResponse> => {
        const url = `/v1/member_groups/${memberGroupId}/experiences/${experienceId}/workflow_drafts/${workflowUUID}/page_drafts/${formUUID}`;
        const response = await this.axios.put<
            KoddiAPIResponse<CreateFormUUIDResponse>
        >(url, body);
        return response.data.result;
    };

    public deleteWorkflowFormDraft = async ({
        memberGroupId,
        experienceId,
        workflowUUID,
        formUUID,
    }: DraftParams): Promise<true> => {
        const url = `/v1/member_groups/${memberGroupId}/experiences/${experienceId}/workflow_drafts/${workflowUUID}/page_drafts/${formUUID}`;
        await this.axios.delete<KoddiAPIResponse<undefined>>(url);
        return true;
    };

    public getFormDraftValues = async ({
        memberGroupId,
        experienceId,
        workflowUUID,
        formUUID,
    }: DraftParams): Promise<any> => {
        const url = `/v1/member_groups/${memberGroupId}/experiences/${experienceId}/workflow_drafts/${workflowUUID}/page_drafts/${formUUID}`;
        const response = await this.axios.get<KoddiAPIResponse<any>>(url);
        return response.data.result;
    };
}

export default KoddiConfiguredWorkflowAPI;
