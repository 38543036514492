import React, { FunctionComponent } from 'react';

import { DropdownMultiOptionMenuProps } from './dropdown.types';
import {
    DropdownMenuList,
    DropdownMenuTitle,
    DropdownMenuListItem,
    DropDownMenuHeaderWrapper,
} from './dropdown.styled';
import { CTextButton } from '../_ChakraComponents';

const DropdownMultiOptionMenu: FunctionComponent<DropdownMultiOptionMenuProps> = ({
    items,
    categoryItems,
    style,
    forwardedRef,
    className,
    visible,
    usePortal,
    minWidth,
    width,
    dropDownMenuTitle,
    linkText,
    onLinkClick,
    ...rest
}) => (
    <DropdownMenuList
        {...rest}
        className={className}
        ref={forwardedRef}
        style={style}
        visible={visible}
        usePortal={usePortal}
        minWidth={minWidth}
        width={width}
    >
        <DropDownMenuHeaderWrapper>
            {dropDownMenuTitle && (
                <DropdownMenuTitle>{dropDownMenuTitle}</DropdownMenuTitle>
            )}
            {linkText && (
                <CTextButton testId="link-text" onClick={onLinkClick}>
                    {linkText}
                </CTextButton>
            )}
        </DropDownMenuHeaderWrapper>
        {items?.map((item, index) => (
            <DropdownMenuListItem
                padding="0px"
                key={`dropdown-item-${item.label || index}`}
                onClick={() => {
                    if (item.onClick) item.onClick();
                }}
            >
                {item.label || item}
            </DropdownMenuListItem>
        ))}

        {categoryItems?.map((item, index) => (
            <DropdownMenuListItem
                padding="0px"
                key={`dropdown-item-${item.key || index}`}
            >
                {item}
            </DropdownMenuListItem>
        ))}
    </DropdownMenuList>
);

export default React.forwardRef<HTMLUListElement, DropdownMultiOptionMenuProps>(
    (props, ref) => <DropdownMultiOptionMenu forwardedRef={ref} {...props} />
) as FunctionComponent<DropdownMultiOptionMenuProps>;
