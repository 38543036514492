import { combineReducers, Reducer, AnyAction } from 'redux';
import produce from 'immer';
import activityReducer from './activity/reducer';
import memberReducer from './member/reducer';
import memberGroupReducer from './memberGroup/reducer';
import { MemberGroupTypesState } from './memberGroupTypes';
import memberGroupTypesReducer from './memberGroupTypes/reducer';
import permissionReducer from './permission/reducer';
import roleReducer from './role/reducer';
import rolePermissionReducer from './rolePermission/reducer';
import userReducer from './user/reducer';
import { AdminState, ResetAdminStateAction, AdminAction } from './types';
import { activityInitialState } from './activity';
import { memberInitialState } from './member';
import { memberGroupInitialState } from './memberGroup';
import { permissionInitialState } from './permission';
import { roleInitialState } from './role';
import { rolePermissionInitialState } from './rolePermission';
import { industryInitialState } from './industry';
import industryReducer from './industry/reducer';
import { userInitialState } from './user';
import { editorInitialState, editorReducer } from './editor';
import { adminAlertInitialState, adminAlertReducer } from './alert';
import currencyReducer from './currency/reducer';
import { currencyInitialState } from './currency';
import localesReducer from './locales/reducer';
import {
    advertiserGroupsReducer,
    initialState as advertiserGroupsInitialState,
} from './advertiserGroups/reducer';
import advertisersReducer from './advertisers/reducer';
import { localesInitialState } from './locales';
import { advertisersInitialState } from './advertisers';
import {
    initialState as advertiserPaymentInitialState,
    advertiserPaymentReducer,
} from './advertiserPayment/reducer';
import timezonesReducer from './timezones/reducer';
import { timezonesInitialState } from './timezones';
import {
    entityRegistrationReducer,
    entityRegistrationState,
} from './entityRegistration';
import resourceHeaderReducer, { initialState } from './resourceHeader/reducer';
import { experiencesInitialState } from './Experiences';
import { experiencesReducer } from './Experiences/reducer';

export const adminInitialState: AdminState = {
    activity: activityInitialState,
    advertiserGroups: advertiserGroupsInitialState,
    advertisers: advertisersInitialState,
    advertiserPayment: advertiserPaymentInitialState,
    alert: adminAlertInitialState,
    editor: editorInitialState,
    entityRegistration: entityRegistrationState,
    experiences: experiencesInitialState,
    member: memberInitialState,
    memberGroup: memberGroupInitialState,
    memberGroupTypes: MemberGroupTypesState,
    permission: permissionInitialState,
    resourceHeader: initialState,
    role: roleInitialState,
    rolePermission: rolePermissionInitialState,
    industry: industryInitialState,
    user: userInitialState,
    currency: currencyInitialState,
    locales: localesInitialState,
    timezones: timezonesInitialState,
};

const adminReducer = combineReducers<AdminState>({
    activity: activityReducer,
    advertiserGroups: advertiserGroupsReducer,
    advertisers: advertisersReducer,
    advertiserPayment: advertiserPaymentReducer,
    alert: adminAlertReducer,
    editor: editorReducer,
    entityRegistration: entityRegistrationReducer,
    experiences: experiencesReducer,
    member: memberReducer,
    memberGroup: memberGroupReducer,
    memberGroupTypes: memberGroupTypesReducer,
    permission: permissionReducer,
    resourceHeader: resourceHeaderReducer,
    role: roleReducer,
    rolePermission: rolePermissionReducer,
    industry: industryReducer,
    user: userReducer,
    currency: currencyReducer,
    locales: localesReducer,
    timezones: timezonesReducer,
});

const adminRootReducer = (
    state: AdminState,
    action: ResetAdminStateAction
): AdminState =>
    produce<AdminState>(state, () => {
        switch (action.type) {
            case AdminAction.RESET_STATE: {
                return adminInitialState;
            }
            default: {
                return adminReducer(state, action);
            }
        }
    });

export default adminRootReducer as Reducer<AdminState, AnyAction>;
