import React from 'react';
import { Badge, BadgeProps, Box } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
    BorderRadius,
    Colors,
    ColorSchemes,
    FontSizes,
    FontWeights,
    IconSizes,
    Spacing,
} from '../ChakraTheme';
import { CFAIcon } from '../Icons';
import { CText } from '../Typography';
import { useChakraTheme } from '../ChakraTheme/useChakraTheme.hooks';

export const CBadgeBase = ({ children, ...props }: BadgeProps): JSX.Element => (
    <Badge
        fontSize={FontSizes.sm}
        fontWeight={FontWeights.regular}
        py={Spacing.XXS}
        px={Spacing.XS}
        variant="outline"
        whiteSpace="break-spaces"
        {...props}
    >
        {children}
    </Badge>
);

export const CBadge = ({ children, ...props }: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme={ColorSchemes.gray} {...props}>
        {children}
    </CBadgeBase>
);

export const CBadgeTheme = ({
    children,
    ...props
}: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme={ColorSchemes.primary} {...props}>
        {children}
    </CBadgeBase>
);

export const CBadgeSuccess = ({
    children,
    ...props
}: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme="green" {...props}>
        {children}
    </CBadgeBase>
);

export const CBadgeError = ({
    children,
    ...props
}: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme="red" {...props}>
        {children}
    </CBadgeBase>
);

export const CBadgeWarning = ({
    children,
    ...props
}: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme="orange" {...props}>
        {children}
    </CBadgeBase>
);

export const CBetaBadge = ({
    ...props
}: Omit<BadgeProps, 'children'>): JSX.Element => (
    <CBadgeBase bg={Colors.primary} variant="solid" {...props}>
        Beta
    </CBadgeBase>
);

export const CTintedBadge = ({
    color,
    icon,
    label,
}: {
    color?: keyof typeof theme.colors;
    icon?: IconDefinition;
    label: string;
}): JSX.Element => {
    const theme = useChakraTheme();
    const primaryTints = theme.colors[color ?? ColorSchemes.primary];
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg={primaryTints[100]}
            w="fit-content"
            borderRadius={BorderRadius.XL}
            py={Spacing.XS}
            px={Spacing.SM}
            gap={Spacing.XS}
            color={primaryTints[600]}
            cursor="pointer"
        >
            {icon && (
                <CFAIcon icon={icon} size={IconSizes.sm} color="inherit" />
            )}
            <CText
                color="inherit"
                text={label}
                fontSize={FontSizes.lg}
                fontWeight={FontWeights.medium}
            />
        </Box>
    );
};
