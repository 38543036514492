import { ButtonStyle } from './button.types';

export const BUTTON_TYPES: Record<ButtonStyle, ButtonStyle> = {
    primary: 'primary',
    secondary: 'secondary',
    tertiary: 'tertiary',
    pill: 'pill',
    rect: 'rect',
    transparent: 'transparent',
    border: 'border',
};

export const COMPONENT_KEY = 'button';
