export const SUPER_USER_ROLE_ID = 1;
export const INTERNAL_ADMIN_ROLE_ID = 2;
export const CLIENT_ADMIN_ROLE_ID = 3;
export const CLIENT_VIEWER_ROLE_ID = 4;
export const ADVERTISER_ADMIN_ROLE_ID = 5;
export const ADVERTISER_MANAGER_ROLE_ID = 6;
export const ADVERTISER_VIEWER_ROLE_ID = 7;
export const ADVERTISER_REPORTER_ROLE_ID = 8;

export const CREATE_CLIENT_ROLES = [SUPER_USER_ROLE_ID];

export const CREATE_AD_GROUP_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
];

export const EDIT_AD_GROUP_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
];

export const CREATE_ADVERTISER_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
];

export const EDIT_ADVERTISER_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
];

export const VIEW_CLIENT_ADVERTISER_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    CLIENT_VIEWER_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
    ADVERTISER_VIEWER_ROLE_ID,
];

export const VIEW_CLIENT_ADVERTISER_GROUP_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    CLIENT_VIEWER_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
    ADVERTISER_VIEWER_ROLE_ID,
];

export const VIEW_CENTRALIZED_CLIENT_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    CLIENT_VIEWER_ROLE_ID,
];

export const VIEW_CLIENT_SETTINGS_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
];

export const VIEW_CLIENT_FUNDING_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
];

export const VIEW_CLIENT_TARGETING_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
];

export const VIEW_CLIENT_TARGETING_CONFIGURATION = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
];

export const TOP_LEVEL_ADMIN_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
];

export const VIEW_CENTRALIZED_ADVERTISER_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
    ADVERTISER_VIEWER_ROLE_ID,
];

export const VIEW_CENTRALIZED_USER_ROLES = [SUPER_USER_ROLE_ID];

export const CENTRALIZED_ADVERTISER_GROUPS_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
];

export const DELETE_ADVERTISER_ENTITY_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
];

export const CREATE_CENTRALIZED_USERS_ROLES = [SUPER_USER_ROLE_ID];
export const EDIT_CENTRALIZED_USERS_ROLES = [SUPER_USER_ROLE_ID];

export const HIGHER_USER_ROLE_IDS = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
];

export const VIEW_PUBLISHER_DASHBOARD_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    CLIENT_VIEWER_ROLE_ID,
];

export const VIEW_PACING_DASHBOARD_ROLES = [
    ...VIEW_PUBLISHER_DASHBOARD_ROLES,
    ADVERTISER_ADMIN_ROLE_ID,
];

export const CLIENT_ROLE_IDS = [
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    CLIENT_VIEWER_ROLE_ID,
];
export const ADVERTISER_ROLE_IDS = [
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
    ADVERTISER_VIEWER_ROLE_ID,
    ADVERTISER_REPORTER_ROLE_ID,
];

export const SET_USER_ADVERTISER_ROLES = [
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    CLIENT_VIEWER_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
];
