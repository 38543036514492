import { Colors } from './Colors.config';

export const GlobalStyles = {
    body: {
        color: Colors.primaryText,
        lineHeight: 'unset',
    },
    p: {
        margin: 'revert',
    },
    h1: {
        margin: 'revert',
    },
    h2: {
        margin: 'revert',
    },
    h3: {
        margin: 'revert',
    },
    h4: {
        margin: 'revert',
    },
    h5: {
        margin: 'revert',
    },
    h6: {
        margin: 'revert',
    },
    '*::before': {
        boxSizing: 'revert',
    },
    '*::after': {
        boxSizing: 'revert',
    },
    '*': {
        borderStyle: 'unset',
    },
};

export const EmbeddedGlobalStyles = {
    ...GlobalStyles,
    body: {
        color: Colors.grayDarkest,
        lineHeight: 'unset',
        background: Colors.white,
    },
};
