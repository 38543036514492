import { Box, Collapse, ScaleFade, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { CRow, CSection } from '../Layouts';
import { CItalicText, CSectionHeader } from '../Typography';
import { CBorderButton } from '../Buttons';
import { useExpandingSection } from './ExpandingSection.hooks';
import { ExpandingSectionProps } from './ExpandingSection.types';
import { BorderRadius, Colors, Spacing } from '../ChakraTheme';

import { CCheckMarkIcon, CChevronDownIcon, CChevronRightIcon } from '../Icons';

export const CExpandingSection = ({
    children,
    testId,
    title,
    isValid,
    isOpenByDefault,
    isOptional,
    isDisabled,
    tooltip,
}: ExpandingSectionProps): JSX.Element => {
    const { isOpen, toggleCollapse } = useExpandingSection({
        isOpenByDefault,
        isDisabled,
    });

    return (
        <Box width="100%">
            <Tooltip
                label={tooltip}
                isDisabled={!tooltip}
                shouldWrapChildren
                placement="top-start"
                mb={Spacing.XS}
            >
                <CBorderButton
                    onClick={toggleCollapse}
                    testId={testId}
                    width="100%"
                    minWidth="100px"
                    justifyContent="space-between"
                    alignContent="center"
                    borderRadius={BorderRadius.MD}
                    padding={Spacing.XXS}
                    isDisabled={isDisabled}
                >
                    <CRow noBreak alignItems="center" gap={Spacing.XS}>
                        <CSectionHeader text={title} mb={0} />
                        {isOptional && (
                            <CItalicText
                                marginLeft={Spacing.XXS}
                                text="(optional)"
                            />
                        )}
                        <ScaleFade in={isValid} initialScale={0}>
                            <CCheckMarkIcon
                                data-test={`${testId}-icon_check`}
                                fill={Colors.white}
                                bg={Colors.success}
                                padding={Spacing.XXS}
                                height="16px"
                                width="16px"
                                borderRadius={BorderRadius.XL}
                                display="block"
                            />
                        </ScaleFade>
                    </CRow>
                    {isOpen ? (
                        <CChevronDownIcon
                            data-test={`${testId}-icon_down`}
                            height="12px"
                            width="12px"
                            fill={Colors.primary}
                        />
                    ) : (
                        <CChevronRightIcon
                            data-test={`${testId}-icon_right`}
                            height="12px"
                            width="12px"
                            fill={Colors.primary}
                        />
                    )}
                </CBorderButton>
            </Tooltip>
            <Collapse in={isOpen} animateOpacity>
                <CSection testId={`${testId}-section`} padding={Spacing.SM}>
                    {children}
                </CSection>
            </Collapse>
        </Box>
    );
};
