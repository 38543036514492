import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import { Breakpoints, Colors, ColorSchemes, Spacing } from '../ChakraTheme';
import { CFAIcon } from '../Icons';
import { useTabs } from './Tabs.hooks';
import { TabData, TabsProps } from './Tabs.types';
import { CIconTooltip } from '../Tooltip/Tooltip.component';
import { CText } from '../Typography';

export const CTabs = ({
    defaultTabId,
    tabs,
    shouldUpdateUrl = true,
    onChange,
    variant = 'line',
    minWidth = Breakpoints.md,
    index,
    setIndex,
    isControlled = false,
    padding = Spacing.SM,
    tabPanelsProps = {},
    ...props
}: TabsProps): JSX.Element => {
    const { handleChange, tabIndex, visibleTabs } = useTabs({
        defaultTabId,
        tabs,
        onChange,
        shouldUpdateUrl,
        isControlled,
        setIndex,
        index,
    });
    return (
        <Tabs
            minWidth={minWidth}
            isLazy
            {...props}
            onChange={handleChange}
            index={tabIndex}
            variant={variant}
            colorScheme={ColorSchemes.primary}
        >
            <TabList>
                {visibleTabs.map((tab: TabData) => (
                    <Tab
                        data-test={`${tab.id}--tab`}
                        key={tab.id}
                        isDisabled={tab.isDisabled}
                        _disabled={{
                            color: Colors.disabled,
                            opacity: 0.35,
                            cursor: 'not-allowed',
                        }}
                        gap={Spacing.XS}
                    >
                        {tab.icon && <CFAIcon icon={tab.icon} />}
                        <CText noWrap>{tab.label}</CText>
                        {tab.tooltip && (
                            <CIconTooltip
                                label={tab.tooltip}
                                bg={Colors.grayDark}
                                iconColor={Colors.gray}
                            />
                        )}
                    </Tab>
                ))}
            </TabList>
            <TabPanels {...tabPanelsProps}>
                {visibleTabs.map((tab: TabData) => (
                    <TabPanel
                        p={padding}
                        key={tab.id}
                        data-test={`${tab.id}--content`}
                        minW="100%"
                        {...(tab?.tabPanelProps ?? {})}
                    >
                        {tab.content}
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
};
