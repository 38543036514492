import { far } from '@fortawesome/pro-regular-svg-icons';
import React, { FunctionComponent } from 'react';
import { CBaseRadioCard } from '../BaseRadioCard.component';
import { VariantRadioCardProps } from '../RadioCard.types';

export const CStackedRadioCard: FunctionComponent<VariantRadioCardProps<
    string | number
>> = ({ ...props }) => {
    return <CBaseRadioCard {...props} iconPackage={far} isStacked />;
};
