import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { Provider, ReactReduxContext } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ConnectedRouter } from 'connected-react-router';
import { QueryParamProvider } from 'use-query-params';
import LocaleProvider from 'koddi-components/LocaleProvider';
import { datadogRum, RumEvent } from '@datadog/browser-rum';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

import store from 'redux-core/store';
import history from 'utils/history';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-circular-progressbar/dist/styles.css';
import { AppConfigWrapper } from './react-ui/hooks/appConfig/appConfig.context';

// below is the regex to include prods origin.
// /^https:\/\/((?!login\.*\.).)*koddi\.io(\/|$)/;
datadogRum.init({
    applicationId: '8d231e00-e3cc-426c-a759-7be37b03036e',
    clientToken: 'pub84524eb84df97e8bdde91c3591ccf996',
    site: 'datadoghq.com',
    service: 'koddi-one',
    env: window.TARGET_ENV,
    version: window.UI_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    trackUserInteractions: true,
    trackViewsManually: true,
    allowedTracingOrigins: [/https:\/\/(?!login\.).*\.koddi\.io\/.*/],
    enableExperimentalFeatures: ['feature_flags'],
    useCrossSiteSessionCookie: true,
    useSecureSessionCookie: true,
    beforeSend: (event: RumEvent): boolean | void => {
        const networkErrors =
            event.type === 'resource' &&
            [400, 401, 403].includes(event.resource?.status_code || 0);
        const cancelledRequests =
            event.type === 'error' &&
            event.error?.message.includes('Request was cancelled');
        const consoleError =
            /* @ts-ignore */
            event?.error?.source === 'console' &&
            /* @ts-ignore */
            event?.error?.handling === 'handled';
        const localhostErrors =
            window.location.hostname.includes('localhost') &&
            event.type === 'error';
        /* @ts-ignore */
        const chunkError = event.error?.message.includes('ChunkLoadError');
        if (
            networkErrors ||
            cancelledRequests ||
            consoleError ||
            localhostErrors ||
            chunkError
        ) {
            return false;
        }
        return true;
    },
});

datadogRum.startSessionReplayRecording();

Modal.setAppElement('#root');

const EmbeddedApp = React.lazy(() => import('features/EmbeddedApp'));
const App = React.lazy(() => import('react-ui/App'));

const Root = () => {
    return (
        <Provider store={store} context={ReactReduxContext}>
            <ConnectedRouter
                history={history}
                context={ReactReduxContext}
                noInitialPop
            >
                <QueryParamProvider ReactRouterRoute={Route}>
                    <DndProvider
                        backend={TouchBackend}
                        options={{ enableMouseEvents: true }}
                    >
                        <LocaleProvider>
                            <Switch>
                                <Route path="/embedded">
                                    <AppConfigWrapper>
                                        <Suspense fallback={<div />}>
                                            <EmbeddedApp />
                                        </Suspense>
                                    </AppConfigWrapper>
                                </Route>
                                <Route path="/">
                                    <AppConfigWrapper>
                                        <Suspense fallback={<div />}>
                                            <App />
                                        </Suspense>
                                    </AppConfigWrapper>
                                </Route>
                            </Switch>
                        </LocaleProvider>
                    </DndProvider>
                </QueryParamProvider>
            </ConnectedRouter>
        </Provider>
    );
};

const RootWithFlags = withLDProvider({
    clientSideID: window.LAUNCH_DARKLY_ID,
    user: {
        key: 'anon',
        custom: {
            deployEnv: window.DEPLOY_ENV,
        },
    },
    options: {
        inspectors: [
            {
                type: 'flag-used',
                name: 'dd-inspector',
                method: (key: string, detail: any) => {
                    datadogRum.addFeatureFlagEvaluation(key, detail.value);
                },
            },
        ],
    },
})(Root);

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<RootWithFlags />);
