/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
import { useMemo, useState, useCallback, useEffect } from 'react';
import { InputActionMeta, ValueType } from 'react-select';
import { useDebouncedCallback } from 'use-debounce/lib';

import { useOnFirstRender } from 'app/react-ui/hooks/useOnFirstRender.hooks';
import { OptionType, selectValue } from '../../../../Types';
import {
    InputActions,
    PaginatedMultiSelectFieldProps,
    PaginatedSelectFieldHookReturn,
} from '../../SelectField.types';

export const useSelectPaginatedField = <
    T extends selectValue = string | number
>({
    onChange,
    inputValue,
    resetSearchOnSelection,
    fetchOptions,
    trackedPagination,
    dependsOn,
    isMulti,
}: Pick<
    PaginatedMultiSelectFieldProps<T>,
    | 'onChange'
    | 'inputValue'
    | 'resetSearchOnSelection'
    | 'fetchOptions'
    | 'trackedPagination'
    | 'dependsOn'
> & { isMulti?: boolean }): PaginatedSelectFieldHookReturn => {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [lastValidInputValue, setLastValidInputValue] = useState<
        typeof inputValue
    >(inputValue);

    const portal = document.getElementById('select-portal');
    useOnFirstRender(() => {
        if (!portal) {
            // eslint-disable-next-line no-console
            console.error(
                'To enable menu portal add  <div id="select-portal"></div> to your DOM'
            );
        }
    });

    // Helper function to sort options with selected items at the top
    const sortWithSelectedAtTop = useCallback(
        (optionsToSort: OptionType[]) => {
            // we want to grab the selected options from the inputValue
            const selectedOptions =
                isMulti && Array.isArray(inputValue) && inputValue.length > 0
                    ? [
                          ...(inputValue as string[]).map((value) => ({
                              value,
                              label: String(value),
                          })),
                      ]
                    : [];

            // we want to filter out the selected options from the optionsToSort (because they are already at the top)
            const optionsToSortWithSelectedExcluded = [...optionsToSort].filter(
                (option) =>
                    !selectedOptions.some((s) => s.value === option.value)
            );

            if (isMulti && Array.isArray(inputValue) && inputValue.length > 0) {
                // Return result with selected at top
                return [
                    ...selectedOptions,
                    ...optionsToSortWithSelectedExcluded,
                ];
            }

            if (!isMulti && inputValue) {
                return [
                    ...selectedOptions,
                    ...optionsToSortWithSelectedExcluded,
                ];
            }

            return optionsToSort;
        },
        [isMulti, inputValue]
    );

    const fetchAndSetOptions = useCallback(
        async (count?: number, pageNumber?: number) => {
            if (isLoading || !hasMore) return;
            setIsLoading(true);

            const data = await fetchOptions(
                searchValue,
                pageNumber ?? page,
                count
            );

            setOptions((prev) => {
                // Filter out duplicates
                const newOptions = data.filter(
                    (d) => !prev.find((p) => p.value === d.value)
                );

                // Combine and sort
                return [...prev, ...newOptions];
            });

            setHasMore(data.length > 0);
            setPage((prev) => prev + 1);
            setIsLoading(false);
        },
        [isLoading, hasMore, fetchOptions, searchValue, page]
    );

    const handleClickOption = useCallback(
        (option: ValueType<OptionType>) => {
            setInitialized(true);
            if (resetSearchOnSelection) {
                setSearchValue('');
            }
            if (!option) {
                onChange?.(undefined);
                setLastValidInputValue(undefined);
                return;
            }

            if (isMulti && Array.isArray(option)) {
                const values = option.map((o) => o.value);

                onChange?.(values as T);
                setLastValidInputValue(
                    values.length > 0 ? (values as T) : undefined
                );

                if (values.length === 0) return;

                return;
            }

            const { value } = option as OptionType<T>;
            onChange?.(value);
            setLastValidInputValue(value);
        },
        [onChange, isMulti, resetSearchOnSelection]
    );

    const resetSearch = useCallback(async () => {
        // reset values
        setOptions([]);
        setPage(0);
        setHasMore(true);
        setIsLoading(true);

        // fetch default options and sort selected at top
        const data = await fetchOptions('', 0);
        const sortedData = sortWithSelectedAtTop(data);
        setOptions(sortedData);
        setHasMore(data.length > 0);
        setPage(1);
        setIsLoading(false);
        return sortedData;
    }, [fetchOptions, sortWithSelectedAtTop]);

    const handleSearchChange = async (
        value: string,
        { action }: InputActionMeta
    ): Promise<OptionType<string | number>[] | undefined> => {
        switch (action) {
            case InputActions.setValue:
                return;
            case InputActions.inputBlur:
            case InputActions.menuClose:
                setSearchValue('');

                // On blur/menu close, ensure selected options are at the top
                if (options.length > 0) {
                    setOptions(sortWithSelectedAtTop(options));
                }
                return;
            case InputActions.inputChange:
                setSearchValue(value);

                // When search is cleared, reset options and fetch initial options
                if (!value || value.trim() === '') {
                    await resetSearch();
                    return;
                }

                // Handle search with value
                setIsLoading(true);
                const data = await fetchOptions(value, 0);
                const sortedData = sortWithSelectedAtTop(data);

                setOptions(sortedData);
                setHasMore(data.length > 0);
                setPage(1);
                setIsLoading(false);
                return sortedData;
            default:
                break;
        }
    };

    // on menu open, fetch options if there are no options and the component is not initialized
    const handleMenuOpen = useCallback(async () => {
        setOpen(true);
        if (options.length === 0 && !initialized) {
            await fetchAndSetOptions();
        }
    }, [initialized, fetchAndSetOptions, options]);

    const valueProp = useMemo(() => {
        const isValidInput = isMulti
            ? Array.isArray(inputValue) && inputValue.length > 0
            : inputValue !== undefined && inputValue !== null;

        const valueToUse = isValidInput ? inputValue : lastValidInputValue;

        // Return null if no value
        if (valueToUse === undefined || valueToUse === null) {
            return { value: null };
        }

        // Handle multi-select case
        if (isMulti && Array.isArray(valueToUse)) {
            const selectedOptions = valueToUse.map(
                (value) =>
                    options?.find((option) => option.value === value) || {
                        value,
                        label: String(value),
                    }
            );

            return { value: selectedOptions };
        }

        // Handle single-select case
        return {
            value: options?.find((option) => option.value === valueToUse) || {
                value: valueToUse,
                label: String(valueToUse),
            },
        };
    }, [inputValue, isMulti, options, lastValidInputValue]);

    // initialize the tracked pagination for page refresh
    useEffect(() => {
        const loadAllPages = async () => {
            setInitialized(true);
            setIsLoading(true);

            const data = await fetchOptions('', 0, trackedPagination?.count);
            const sortedData = sortWithSelectedAtTop(data);

            setOptions(sortedData);
            setPage(trackedPagination?.page ?? 0);
            setHasMore(true);
            setIsLoading(false);
        };
        if (trackedPagination && !initialized && inputValue) {
            loadAllPages();
        }
    }, [
        trackedPagination,
        dependsOn,
        inputValue,
        fetchOptions,
        initialized,
        isMulti,
        sortWithSelectedAtTop,
    ]);

    const debouncedChange = useDebouncedCallback(handleClickOption, 100);

    return {
        portal,
        open,
        setOpen,
        valueProp: valueProp as { value?: OptionType<string | number> },
        searchValue,
        options,
        isLoading,
        handleSearchChange,
        handleMenuOpen,
        debouncedChange,
        fetchAndSetOptions,
    };
};
