import React, {
    useRef,
    useCallback,
    useMemo,
    useState,
    useEffect,
    FunctionComponent,
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Field } from 'react-final-form';
import {
    useLocale,
    KODDI_LOCALE_TO_DATEFNS,
    KoddiLocaleKey,
} from 'koddi-components/LocaleProvider';
import Label from 'koddi-components/Label';
import { Portal } from 'react-portal';
import { usePopper } from 'react-popper';
import { useKoddiTheme } from 'koddi-components/ThemeProvider';
import Checkbox from 'koddi-components/Checkbox';
import Input from '../Input';
import SingleDatePickerHeader from './SingleDatePickerHeader';
import {
    SingleDatePickerProps,
    SingleDatePickerFieldProps,
    SingleDatePickerFieldComponentProps,
    CustomInputProps,
} from './SingleDatePicker.types';
import {
    BaseSingleDatePickerStyles,
    InputIcon,
    SingleDatePickerCheckboxContainer,
    SingleDatePickerContainer,
    SingleDatePickerWrapper,
    LabelWrapper,
    LabelIcon,
} from './SingleDatePicker.styled';
import { SingleDatePickerHeaderProps } from './SingleDatePickerHeader/SingleDatePickerHeader.types';

Object.keys(KODDI_LOCALE_TO_DATEFNS).forEach((localeKey) => {
    registerLocale(
        localeKey,
        KODDI_LOCALE_TO_DATEFNS[localeKey as KoddiLocaleKey]
    );
});

/**
 * The Koddi Date Single Date Picker Component.
 */
export const SingleDatePicker = ({
    className,
    prevMonthButtonDisabled = false,
    nextMonthButtonDisabled = false,
    onDayHover,
    openToDate,
    customInput,
    showInputErrors = true,
    onChange,
    selected,
    label,
    StylesComponent = BaseSingleDatePickerStyles,
    stylesComponentProps,
    showAlwaysOnCheckbox = false,
    alwaysOnCheckboxText = 'Always On',
    alwaysOnCheckboxName = 'always_on',
    alwaysOnCheckboxInitialValue = false,
    onAlwaysOnCheckboxChange,
    filterDate,
    usePortal = true,
    useAriaLabel = false,
    disablePastMonths = false,
    customZindex,
    placeholderText,
    disabled,
    minWidth,
    required,
    customChevronIconMargin,
    popperModifier,
    tooltipText,
    v2 = false,
    fixedBorderWidth,
    'data-test': dataTest,
    convertToISOString = false,
    ...rest
}: SingleDatePickerProps): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);

    const { zIndex } = useKoddiTheme();
    const [alwaysOnChecked, setAlwaysOnChecked] = useState<boolean>(
        alwaysOnCheckboxInitialValue
    );
    const [
        popperReference,
        setPopperReference,
    ] = useState<HTMLDivElement | null>(null);
    const [popper, setPopper] = useState<HTMLDivElement | null>(null);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const popperRef = useRef<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(popperReference, popper, {
        modifiers: popperModifier || [],
        placement: 'bottom-start',
    });
    const { locale, formatDate } = useLocale();
    const { input, meta } = rest;
    const name = input?.name || rest.name;

    const visibility = !disabled && required ? 'visible' : 'hidden';

    const handleCheckboxChange = useCallback(() => {
        setAlwaysOnChecked(!alwaysOnChecked);
        onAlwaysOnCheckboxChange?.(alwaysOnCheckboxName, !alwaysOnChecked);
    }, [alwaysOnChecked, onAlwaysOnCheckboxChange, alwaysOnCheckboxName]);

    const handleOnChange = useCallback(
        (date: Date) => {
            const value = convertToISOString ? date.toISOString() : date;
            if (onChange) onChange(value);
            setAlwaysOnChecked(false);
            onAlwaysOnCheckboxChange?.(alwaysOnCheckboxName, false);
            rest.input?.onChange({
                target: {
                    value,
                },
            });
        },
        [
            convertToISOString,
            onChange,
            onAlwaysOnCheckboxChange,
            rest.input,
            alwaysOnCheckboxName,
        ]
    );

    const date: any = input?.value ? new Date(input?.value) : selected;

    const RenderCustomInput = useMemo(() => {
        if (customInput === undefined) {
            const CustomInput = ({
                value,
                name: inputName,
                forwardedRef,
                onClick,
                ...restInputProps
            }: CustomInputProps) => {
                const icon = calendarOpen ? 'chevronUp' : 'chevronDown';
                const IconRight = (
                    <InputIcon
                        icon={icon}
                        width={8}
                        height={8}
                        disabled={disabled}
                        onClick={
                            !disabled
                                ? (e: any) => {
                                      if (onClick) onClick(e);
                                      setCalendarOpen(!calendarOpen);
                                  }
                                : undefined
                        }
                    />
                );

                return (
                    <Input
                        {...restInputProps}
                        id={inputName}
                        name={inputName}
                        data-test={dataTest}
                        minWidth={minWidth}
                        noMarginBottom
                        inputContainerRef={setPopperReference}
                        ref={forwardedRef}
                        value={alwaysOnChecked ? alwaysOnCheckboxText : value}
                        onClick={onClick}
                        onContainerClick={
                            !disabled
                                ? (e: any) => {
                                      if (onClick) onClick(e);
                                  }
                                : undefined
                        }
                        placeholder={placeholderText}
                        showErrors={showInputErrors}
                        iconRight={disabled ? null : IconRight}
                        useAriaLabel={useAriaLabel}
                        label={useAriaLabel ? label : undefined}
                        disabled={disabled}
                        customChevronIconMargin={customChevronIconMargin}
                        v2={v2}
                        fixedBorderWidth={fixedBorderWidth}
                    />
                );
            };
            const CustomInputWithRef = React.forwardRef<
                HTMLInputElement,
                CustomInputProps
            >((props, inputRef) => (
                <CustomInput {...props} forwardedRef={inputRef} />
            ));
            return <CustomInputWithRef />;
        }
        return customInput;
    }, [
        customInput,
        calendarOpen,
        disabled,
        minWidth,
        alwaysOnChecked,
        alwaysOnCheckboxText,
        placeholderText,
        showInputErrors,
        useAriaLabel,
        label,
        customChevronIconMargin,
        v2,
        fixedBorderWidth,
        dataTest,
    ]);

    const CalendarContainer = useMemo(() => {
        const CalendarContainerElement: FunctionComponent = ({
            children,
            ...restCalendarContainerProps
        }) => (
            <SingleDatePickerContainer {...restCalendarContainerProps}>
                {children}
                {showAlwaysOnCheckbox && (
                    <SingleDatePickerCheckboxContainer>
                        <Checkbox
                            name={alwaysOnCheckboxName}
                            label={alwaysOnCheckboxText}
                            checked={alwaysOnChecked}
                            onChange={handleCheckboxChange}
                        />
                    </SingleDatePickerCheckboxContainer>
                )}
            </SingleDatePickerContainer>
        );
        return CalendarContainerElement;
    }, [
        alwaysOnCheckboxName,
        alwaysOnCheckboxText,
        alwaysOnChecked,
        handleCheckboxChange,
        showAlwaysOnCheckbox,
    ]);

    const PopperContainer = useMemo(() => {
        if (usePortal) {
            const PopperContainerElement = ({
                children,
            }: {
                children: React.ReactNode[];
            }) => (
                <Portal>
                    <StylesComponent
                        ref={popperRef}
                        style={{
                            ...styles.popper,
                            zIndex: customZindex || zIndex.dropdown,
                        }}
                        className="react-datepicker"
                        {...attributes.popper}
                        {...stylesComponentProps}
                        v2={v2}
                    >
                        {children}
                    </StylesComponent>
                </Portal>
            );
            return PopperContainerElement;
        }
        return undefined;
    }, [
        usePortal,
        customZindex,
        styles.popper,
        zIndex.dropdown,
        attributes.popper,
        stylesComponentProps,
        v2,
    ]);

    const RenderCustomHeader = useMemo(() => {
        const CustomHeader = ({
            date: headerDate,
            ...restHeaderProps
        }: SingleDatePickerHeaderProps) => (
            <SingleDatePickerHeader
                {...restHeaderProps}
                date={openToDate || headerDate}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
                disablePastMonths={disablePastMonths}
                v2={v2}
            />
        );
        return CustomHeader;
    }, [
        nextMonthButtonDisabled,
        openToDate,
        prevMonthButtonDisabled,
        disablePastMonths,
        v2,
    ]);

    useEffect(() => {
        if (popperRef.current && popper === null) {
            setPopper(popperRef.current);
        }
    }, [popper]);

    return (
        <StylesComponent
            className={className}
            ref={ref}
            {...stylesComponentProps}
            v2={v2}
        >
            {label && useAriaLabel === false ? (
                <LabelWrapper visibility={visibility}>
                    <Label htmlFor={name}>{label}</Label>
                    {tooltipText ? (
                        <LabelIcon
                            defaultText={tooltipText}
                            icon="info"
                            iconHeight={13}
                            iconWidth={13}
                        />
                    ) : null}
                </LabelWrapper>
            ) : null}
            <SingleDatePickerWrapper>
                <DatePicker
                    {...rest}
                    {...input}
                    {...meta}
                    popperContainer={PopperContainer}
                    selected={date}
                    openToDate={openToDate}
                    name={input?.name || name}
                    customInput={RenderCustomInput}
                    renderCustomHeader={RenderCustomHeader}
                    filterDate={filterDate}
                    renderDayContents={(dayOfMonth, dayDate) => (
                        <span data-test={dayDate ? formatDate(dayDate) : ''}>
                            {dayOfMonth}
                        </span>
                    )}
                    locale={locale}
                    value={date ? formatDate(date) : ''}
                    onChange={handleOnChange}
                    allowSameDay
                    required
                    onDayMouseEnter={onDayHover}
                    enableTabLoop={false}
                    calendarContainer={CalendarContainer}
                    onCalendarOpen={() => setCalendarOpen(true)}
                    onCalendarClose={() => setCalendarOpen(false)}
                    disabledKeyboardNavigation={alwaysOnChecked}
                    formatWeekDay={(nameOfDay) =>
                        v2
                            ? nameOfDay.substring(0, 3)
                            : nameOfDay.substring(0, 2)
                    }
                />
            </SingleDatePickerWrapper>
        </StylesComponent>
    );
};

/**
 * The `SingleDatePickerField` is a strongly typed `SingleDatePicker`
 * that uses the `Field` from `react-final-form`.
 *
 * It prevents us from having to re-type these type definitions
 * whenever we need to use a `SingleDatePicker` with a `Field` component.
 */
export function SingleDatePickerField({
    name,
    ...props
}: SingleDatePickerFieldProps): JSX.Element {
    const { input } = props;
    return (
        <Field<
            string | null,
            SingleDatePickerFieldComponentProps,
            HTMLInputElement
        >
            allowNull
            {...props}
            name={name}
            component={SingleDatePicker}
            onChange={input?.onChange}
        />
    );
}

export default SingleDatePicker;
