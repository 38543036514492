import { Heading, Text } from '@chakra-ui/react';
import React from 'react';

import { Colors } from '../ChakraTheme/configs/Colors.config';
import { Spacing } from '../ChakraTheme/configs/Spacing.config';
import { FontSizes } from '../ChakraTheme/configs/Typography.config';
import { TypeProps, LabelProps } from './Typography.type';

// https://v1.chakra-ui.com/docs/components/typography/heading

export const CHeader = ({ text, ...props }: TypeProps): JSX.Element => (
    <Heading
        as="h1"
        size="lg"
        fontWeight="normal"
        lineHeight="initial"
        mb={0}
        {...props}
    >
        {text}
    </Heading>
);

export const CSubHeader = ({ text, ...props }: TypeProps): JSX.Element => (
    <Heading as="h2" size="md" {...props}>
        {text}
    </Heading>
);

export const CSectionHeader = ({ text, ...props }: TypeProps): JSX.Element => (
    <Heading as="h3" size="sm" mb={0} variant="bold" {...props}>
        {text}
    </Heading>
);

export const CSectionSubHeader = ({
    text,
    ...props
}: TypeProps): JSX.Element => (
    <Heading as="h4" size="sm" variant="bold" {...props}>
        {text}
    </Heading>
);

// https://v1.chakra-ui.com/docs/components/typography/text
export const CText = ({
    text,
    noWrap,
    children,
    isTruncated,
    ...props
}: TypeProps): JSX.Element => (
    <Text
        m={0}
        whiteSpace={noWrap ?? isTruncated ? 'nowrap' : 'unset'}
        noOfLines={isTruncated ? 1 : undefined}
        wordBreak={isTruncated ? 'break-all' : undefined}
        display={isTruncated ? 'block' : undefined}
        {...props}
    >
        {text || children}
    </Text>
);

export const CBoldText = ({
    text,
    children,
    ...props
}: TypeProps): JSX.Element => (
    <CText {...props} variant="bold">
        {text || children}
    </CText>
);

export const CFormLabel = ({
    label,
    id,
    isRequired,
    ...props
}: LabelProps): JSX.Element => (
    <Text
        htmlFor={id}
        as="label"
        // don't change matches current space
        mb="10px"
        {...props}
        variant="label"
        display="block"
    >
        {`${label} ${isRequired ? '*' : ''}`}
    </Text>
);

export const CErrorText = ({ text, ...props }: TypeProps): JSX.Element => (
    <Text {...props} variant="error" fontSize={FontSizes.sm}>
        {text}
    </Text>
);

export const CFormError = ({ ...props }: TypeProps): JSX.Element => (
    <CErrorText
        {...props}
        as="span"
        mt={Spacing.XXS}
        display="block"
        minH={FontSizes.xl}
    />
);

export const CSmallText = ({ text, ...props }: TypeProps): JSX.Element => (
    <Text m={0} fontSize={FontSizes.sm} {...props}>
        {text}
    </Text>
);

export const CExtraSmallText = ({ text, ...props }: TypeProps): JSX.Element => (
    <Text m={0} fontSize={FontSizes.xs} {...props}>
        {text}
    </Text>
);

export const CSubtleText = ({ ...props }: TypeProps): JSX.Element => (
    <CSmallText
        fontStyle="italic"
        as="i"
        marginBottom={Spacing.XS}
        {...props}
    />
);

export const CItalicText = ({
    text,
    children,
    ...props
}: TypeProps): JSX.Element => (
    <Text as="i" {...props}>
        {text || children}
    </Text>
);

/**
 * @deprecated use CSecondaryText
 */
export const CGrayText = ({ text, ...props }: TypeProps): JSX.Element => (
    <Text color={Colors.grayDark} {...props}>
        {text}
    </Text>
);

export const CSecondaryText = ({ text, ...props }: TypeProps): JSX.Element => (
    <Text color={Colors.secondaryText} {...props}>
        {text}
    </Text>
);

export const CLabeledValue = ({
    value,
    label,
    ...props
}: Omit<TypeProps, 'text'> & { label: string; value: string }): JSX.Element => (
    <CText color={Colors.secondaryText} {...props}>
        <CBoldText color={Colors.primaryText} text={`${label} `} as="span" />
        {value}
    </CText>
);
