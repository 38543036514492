import { useSelector } from 'react-redux';

import {
    selectAppContextInitialized,
    selectAppContextStatus,
} from 'redux-core/app/context/selectors';
import { useAdvertisersLoading } from 'redux-core/app/advertisers/hooks';
import {
    useThemeStatus,
    useThemeInitialized,
} from 'redux-core/app/theme/hooks';
import { selectAppSetupStatus } from './selectors';

export function useAppStatus(): string {
    return useSelector(selectAppSetupStatus);
}

export function useIsAppReady(): boolean {
    const setupStatus = useAppStatus();
    const appContextStatus = useSelector(selectAppContextStatus);
    const contextInitialized = useSelector(selectAppContextInitialized);
    const advertisersLoading = useAdvertisersLoading();
    const themeStatus = useThemeStatus();
    const themeInitialized = useThemeInitialized();
    const themeLoading =
        !themeInitialized &&
        (themeStatus === 'pending' || themeStatus === 'idle');
    const settingUpApp = setupStatus === 'pending' || setupStatus === 'idle';
    const appContextLoading =
        !contextInitialized &&
        (appContextStatus === 'idle' || appContextStatus === 'pending');

    return (
        !settingUpApp &&
        !appContextLoading &&
        !advertisersLoading &&
        !themeLoading
    );
}
