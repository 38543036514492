import React from 'react';
import { CPrimaryButton, CSecondaryButton } from '../../Buttons';
import { Spacing } from '../../ChakraTheme';
import { CRow } from '../../Layouts';
import {
    ModalSubmitText,
    FooterButtonGroupProps,
    ModalTypes,
} from '../Modal.types';

export const ModalFooterButtonGroup = ({
    type,
    handleSubmit,
    isSubmitting = false,
    handleClose,
    id,
    submitButtonText,
    disableSubmit,
}: FooterButtonGroupProps): JSX.Element | null => {
    if (type === ModalTypes.Confirmation || type === ModalTypes.Form)
        return (
            <CRow noBreak mt={Spacing.MD} justifyContent="end">
                <CSecondaryButton
                    onClick={handleClose}
                    testId={`${id}--modal--footer_cancel`}
                >
                    Cancel
                </CSecondaryButton>
                <CPrimaryButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                    testId={`${id}--modal--footer_submit`}
                    isDisabled={disableSubmit}
                >
                    {submitButtonText || ModalSubmitText.Continue}
                </CPrimaryButton>
            </CRow>
        );
    if (type === ModalTypes.Information)
        return (
            <CPrimaryButton
                isLoading={isSubmitting}
                onClick={handleSubmit}
                testId={`${id}--modal--footer_exit`}
            >
                {submitButtonText || ModalSubmitText.Exit}
            </CPrimaryButton>
        );
    return null;
};
