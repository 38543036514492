import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { CChevronRightIcon } from 'app/koddi-components/src/_ChakraComponents/Icons/';
import { Link } from 'react-router-dom';
import { BreadcrumbComponentProps } from './Breadcrumbs.types';
import { BorderWidth, FontWeights, Spacing, Colors } from '../ChakraTheme';

export const CBreadcrumbs = ({
    breadcrumbs = [],
    testId,
    ...props
}: BreadcrumbComponentProps): JSX.Element | null => {
    if (!breadcrumbs?.length) return null;
    return (
        <Breadcrumb
            {...props}
            borderBottomColor={Colors.grayLighter}
            borderBottomWidth={BorderWidth.Thinnest}
            borderBottomStyle="solid"
            pb={Spacing.XS}
            mb={Spacing.SM}
            mt={0}
            pt={Spacing.SM}
            data-test={testId}
            separator={
                <CChevronRightIcon
                    width="8px"
                    height="8px"
                    mb={Spacing.XXS}
                    fill={Colors.gray}
                />
            }
            spacing={Spacing.XS}
        >
            {breadcrumbs.map(({ to, title, noTranslate }, i) => {
                const isLastCrumb = i === breadcrumbs.length - 1;
                return (
                    <BreadcrumbItem
                        key={`${to}-${title}`}
                        data-test={`breadcrumbs-wrapper-${i}`}
                    >
                        <BreadcrumbLink
                            data-test="breadcrumbs-link"
                            as={Link}
                            to={`${to}` || '/admin'}
                            translate={noTranslate ? 'no' : 'yes'}
                            fontWeight={
                                isLastCrumb
                                    ? FontWeights.bold
                                    : FontWeights.regular
                            }
                            isCurrentPage={isLastCrumb}
                        >
                            {title}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};
