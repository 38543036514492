import React from 'react';
import { Box } from '@chakra-ui/react';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { CFormError } from '../../../Typography';
import { OptionType, selectValue } from '../../Types';
import { VirtualizedMenuList } from './atoms/VirtualizedMenuList.atom';
import { useSelectField, useSelectFieldTheme } from './SelectField.hook';
import { SelectFieldBaseProps } from './SelectField.types';
import { Opacity } from '../../../ChakraTheme';
import { OptionWithTooltip } from './atoms/OptionWithToolTip.atom';
import { GroupHeading } from './atoms/GroupHeading.atom';

export const CSelectFieldBase = <T extends selectValue = string | number>({
    testId,
    onChange,
    onClose,
    errorText,
    name,
    inputValue,
    hideError = false,
    isVirtualized = false,
    options,
    groupedOptions,
    isMulti,
    closeMenuOnSelect,
    components,
    isControlledInput,
    height,
    minWidth,
    isDisabled,
    placeholder,
    width,
    maxWidth,
    resetSearchOnSelection = false,
    shouldWrap,
    ...props
}: SelectFieldBaseProps<T>): JSX.Element => {
    const {
        handleChange,
        valueProp,
        defaultOptions,
        loadOptions,
        portal,
        open,
        setOpen,
        handleSearchChange,
        searchValue,
    } = useSelectField({
        onChange,
        options,
        groupedOptions,
        inputValue,
        isMulti,
        isControlledInput,
        resetSearchOnSelection,
    });

    const { styles, theme } = useSelectFieldTheme({
        height,
        errorText,
        shouldWrap,
        isMulti,
    });

    return (
        <Box
            data-test={testId}
            maxWidth={maxWidth}
            minWidth={`${minWidth ?? 150}px`}
            width={width}
            opacity={isDisabled ? Opacity.High : 'unset'}
            cursor={isDisabled ? 'not-allowed' : 'unset'}
        >
            {isVirtualized ? (
                <SelectAsync<OptionType>
                    {...props}
                    {...valueProp}
                    components={{
                        GroupHeading,
                        Option: OptionWithTooltip,
                        ...components,
                        MenuList: VirtualizedMenuList,
                    }}
                    closeMenuOnScroll
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions={defaultOptions}
                    onChange={handleChange}
                    onInputChange={handleSearchChange}
                    inputId={name}
                    inputValue={searchValue}
                    styles={styles}
                    theme={theme}
                    menuPortalTarget={portal}
                    isMulti={isMulti}
                    closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
                    hideSelectedOptions={false}
                    menuPlacement="auto"
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    menuIsOpen={open}
                    onMenuOpen={() => setOpen(true)}
                    onMenuClose={() => {
                        setOpen(false);
                        onClose?.();
                    }}
                />
            ) : (
                <Select<OptionType>
                    {...props}
                    {...valueProp}
                    components={{
                        GroupHeading,
                        Option: OptionWithTooltip,
                        ...components,
                    }}
                    closeMenuOnScroll
                    options={defaultOptions}
                    onChange={handleChange}
                    onInputChange={handleSearchChange}
                    inputId={name}
                    inputValue={searchValue}
                    styles={styles}
                    theme={theme}
                    menuPortalTarget={portal}
                    isMulti={isMulti}
                    closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
                    hideSelectedOptions={false}
                    menuPlacement="auto"
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    openMenuOnClick
                    menuIsOpen={open}
                    onMenuOpen={() => setOpen(true)}
                    onMenuClose={() => {
                        setOpen(false);
                        onClose?.();
                    }}
                />
            )}
            {!hideError && (
                <CFormError data-test={`${name}--error`} text={errorText} />
            )}
        </Box>
    );
};
