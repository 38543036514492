import React from 'react';
import {
    MenuListComponentProps,
    components as ReactSelectComponents,
} from 'react-select';
import { List, ScrollParams } from 'react-virtualized';
import { OptionType } from '../../../Types';

export const VirtualizedMenuList: any = ({
    children,
    selectProps,
}: {
    children: Element[];
} & MenuListComponentProps<
    OptionType<string | number | string[] | number[]>
>) => {
    const rows = children;
    const hasOptions = !!rows.length;
    const rowHeight = 35.5;
    const rowCount = rows?.length ?? 0;
    const totalRowHeight = rowHeight * rowCount;
    const listHeight = totalRowHeight <= 300 ? totalRowHeight : 300;

    const rowRenderer = ({ key, index, style }: any) => (
        <div style={style} key={key}>
            {rows[index]}
        </div>
    );

    // Access the onMenuScrollToBottom from selectProps
    const onMenuScrollToBottom = selectProps?.onMenuScrollToBottom;

    const handleScroll = (params: ScrollParams) => {
        // Check if we're near the bottom
        if (
            onMenuScrollToBottom &&
            params.scrollTop + params.clientHeight >= params.scrollHeight - 20
        ) {
            (onMenuScrollToBottom as () => void)();
        }
    };

    if (hasOptions) {
        return (
            <>
                <List
                    style={{ width: '100%' }}
                    width={100000000}
                    height={listHeight}
                    rowHeight={rowHeight}
                    rowCount={rowCount}
                    rowRenderer={rowRenderer}
                    onScroll={handleScroll}
                />
            </>
        );
    }

    /* @ts-ignore */
    return <ReactSelectComponents.NoOptionsMessage {...children.props} />;
};
