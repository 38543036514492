import { useContext } from 'react';
import { KoddiAuthContext } from './Auth.context';
import { KoddiAuthContextValues } from './Auth.type';

export const useKoddiAuthContext = (): KoddiAuthContextValues => {
    const context = useContext(KoddiAuthContext);
    if (!context) {
        throw Error(
            'useKoddiAuthContext must be used with in  the KoddiAuthContext'
        );
    }
    return context;
};
