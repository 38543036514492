import { fas } from '@fortawesome/pro-solid-svg-icons';
import React, { FunctionComponent } from 'react';
import { CBaseRadioCard } from '../BaseRadioCard.component';
import { VariantRadioCardProps } from '../RadioCard.types';

export const CRadioCard: FunctionComponent<VariantRadioCardProps<
    string | number
>> = ({ ...props }) => {
    return <CBaseRadioCard {...props} iconPackage={fas} />;
};
