export enum Spacing {
    XXXS = '2px',
    XXS = '4px',
    XS = '8px',
    SM = '16px',
    MD = '24px',
    LR = '32px',
    XL = '48px',
    XXL = '64px',
    XXXL = '96px',
    XXXXL = '128px',
}
