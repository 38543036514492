import {
    BorderWidth,
    Colors,
    FontWeights,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import {
    CChevronDownThinIcon,
    CChevronLeftThinIcon,
    CChevronRightThinIcon,
    CChevronUpThinIcon,
} from 'app/koddi-components/src/_ChakraComponents/Icons';
import React, { useMemo } from 'react';
import { CBorderButton } from '../Buttons.component';
import { ChevronButtonProps } from '../Button.type';

export const CChevronButton = ({
    chevronDirection,
    ...props
}: ChevronButtonProps): JSX.Element => {
    const icon = useMemo(() => {
        switch (chevronDirection) {
            case 'up':
                return <CChevronUpThinIcon fill="inherit" />;
            case 'down':
                return <CChevronDownThinIcon fill="inherit" />;
            case 'left':
                return <CChevronLeftThinIcon fill="inherit" />;
            case 'right':
                return <CChevronRightThinIcon fill="inherit" />;
            default:
                return <></>;
        }
    }, [chevronDirection]);

    return (
        <CBorderButton
            {...props}
            color={Colors.black}
            fill={Colors.black}
            bg={Colors.white}
            borderColor={Colors.grayLight}
            fontWeight={FontWeights.regular}
            border={BorderWidth.Thinnest}
            rightIcon={icon}
        />
    );
};
